import _ from 'lodash';

export const schema = {
    title: 'User',
    type: 'object',
    required: ['email', 'first_name', 'last_name'],
    properties: {
        id: { type: 'integer' },
        status: {
            type: 'string',
            title: 'Active',
            default: 'active',
        },

        image: {
            type: ['string', 'null'],
            title: 'Photo',
        },
        username: {
            title: 'Username',
            type: 'string',
        },
        password: {
            title: 'Password',
            type: 'string',
        },
        user_type: {
            type: 'string',
            title: 'User type',
            default: 'member',
            anyOf: [
                {
                    title: 'Member',
                    enum: ['member']
                }
            ]
        },
        first_name: {
            title: 'First name',
            type: 'string',
        },
        last_name: {
            title: 'Last name',
            type: 'string',
        },
        email: {
            title: 'Email',
            type: 'string',
            format: 'email',
        },
        internal_number: { type: ['number', 'null'], title: 'Internal number' },
        generic_pin: { type: ['number', 'null'], title: 'PIN' },
        employee_percent: { type: 'number', title: 'Employee percent', default: 100 },
        address: { type: ['string', 'null'], title: 'Address' },
        post_number: { type: ['string', 'null'], title: 'Post no', format: 'postnumber' },
        post_place: { type: ['string', 'null'], title: 'Post place' },
        full_name: { type: ['string', 'null'], title: 'Full name' },
        phone: { type: ['string', 'null'], title: 'Phone', format: 'phone-no' },
        g_nr: { type: ['string', 'null'], title: 'G.Nr' },
        b_nr: { type: ['string', 'null'], title: 'B.Nr' },
        description: { type: ['string', 'null'], title: 'Description' },
        timelog_start_from: { type: ['string', 'null'], format: 'date', title: 'Start date' },
        timelog_last_filled: { type: ['string', 'null'], format: 'date', title: 'Work logs last filled' },
        birthday: { type: ['string', 'null'], format: 'date', title: 'Date of birth' },
        social_number: { type: ['string', 'null'], format: 'social-no', title: 'Date of birth no.' },
        attachments: { type: ['string', 'null'], title: 'Attachments' },
        vacation_days: { type: "number", title: "Vacation Days" },
        hourly_rate: { type: ['number', 'null'], title: 'Hourly/Fixed Rate' },
        payment_mode: { type: "string", title: "Select payment mode" },
        fixed_hourly_rate: { type: ['number', 'null'], title: 'Calculated hourly rate', default: 0, readOnly: true },
        weekend_extra_payment_percent: { type: ['number', 'null'], title: 'Weekend extra percent (%)', default: 0 },
        expected_fixed_hours: { type: ['number', 'null'], title: 'Estimated hours', default: 0 },
        calculated_weekly_hourly_payment: { type: ['number', 'null'], title: 'Calulated Weekend Hourly Payment', default: 0, readOnly: true },
        disable_autolog: {
            type: ['boolean', 'null'],
            title: 'Disable auto-log',
            default: true,
        },
        home_payment_enabled: {
            type: ['boolean', 'null'],
            title: 'I am paid to come to work place',
            default: false,
        },
        gps_data: { type: ['string', 'null'], title: 'GPS location', format: 'gps' },
        data: {
            type: 'object',
            properties: {
                holidays: {
                    type: 'string',
                    title: 'Default holidays',
                },
                kids: {
                    type: 'array',
                    title: 'Info About Kids',
                    default: [],
                    items: {
                        type: 'object',
                        required: ['name', 'date'],
                        properties: {
                            name: {
                                title: 'Name',
                                type: 'string',
                            },
                            date: {
                                title: 'Date of birth',
                                type: 'string',
                                format: 'date',
                            },
                            chronic_disease: {
                                type: 'string',
                                title: 'Has chronic deasease?',
                                anyOf: [
                                    {
                                        title: 'No',
                                        enum: ['no'],
                                    },
                                    {
                                        title: 'Yes',
                                        enum: ['yes'],
                                    },
                                ],
                                default: 'no',
                            },
                        },
                    },
                },
                is_single_parent: {
                    type: 'boolean',
                    title: 'Are you a single parent',
                    default: false,
                },
                nomines: {
                    type: 'array',
                    title: 'Nomines',
                    default: [],
                    items: {
                        type: 'object',
                        required: ['name', 'contactNumber'],
                        properties: {
                            name: {
                                title: 'Name',
                                type: 'string',
                                format: 'required-name',
                            },
                            contactNumber: {
                                title: 'Contact Number',
                                type: 'string',
                                format: 'phone-no',
                            },
                        },
                    },
                },
                enable_pause_button: { type: 'boolean', title: 'Enable tracked pause', default: false },
                disable_manual_log: { type: 'boolean', title: 'Hide manual time tracking', default: false },
            },
        },
        MemberInProjects: {
            type: 'string',
            title: 'Member of projects',
        },
        ManagesUsers: {
            type: 'array',
            title: 'Manages',
            default: [],
            items: {
                type: 'object',
                required: ['id'],
                properties: {
                    id: {
                        title: 'Member',
                        type: 'integer',
                    },
                },
            },
        },
        allow_tip: {
            type: 'boolean',
            title: 'Allow Tips',
            default: false,
            anyOf: [
                {
                    title: 'Yes',
                    enum: [true],
                },
                {
                    title: 'No',
                    enum: [false],
                },
            ],
        },
        employee_type: {
            type: 'string',
            title: 'Employee type',
        },
        groups: {
            type: 'string',
            title: 'Groups',
        },
        payment_cycle: {
            type: 'string',
            title: 'Payment period',
            default: 'monthly',
            anyOf: [
                {
                    title: 'Monthly',
                    enum: ['monthly'],
                },
                {
                    title: 'Weekly',
                    enum: ['weekly'],
                },
            ],
        },
        allow_mobile_app_access: {
            type: 'boolean',
            title: 'Allow mobile access',
            default: false,
        },
        departmentIntegration: {
            type: ['number', 'null'],
            title: 'Department (Tripletex)',
        },
        bank_account_number: { type: ['string', 'null'], title: 'Bank Account', format: 'bank_account_number' },
        extra_payments: { type: ['string', 'null'], title: 'Extra Payments', default: 'weekends' }
    }
};

export const getSchema = props => {
    if (!props) return schema;
    const { config, mode, userType, currentEntityUser, currentUser } = props;

    const _schema = _.cloneDeep(schema);
    _schema.properties.payment_mode = {
        type: 'string',
        title: 'Select payment mode',
        default: 'hourly',
        anyOf: [
            {
                title: "Hourly",
                enum: ["hourly"]
            },
            {
                title: "Fixed",
                enum: ["fixed"]
            }
        ],
    };

    if (mode === 'members' || mode === 'superadmins') {
        delete _schema.properties.data;
    }

    if (userType === 'admin') {
        _schema.properties.user_type = {
            type: 'string',
            title: 'User type',
            default: 'member',
            anyOf: [
                {
                    title: 'Admin',
                    enum: ['admin'],
                },
                {
                    title: 'PM',
                    enum: ['pm'],
                },
                {
                    title: 'Member',
                    enum: ['member'],
                },
            ],
        };
    } else {
        _schema.properties.user_type.anyOf.push({
            title: 'PM',
            enum: ['pm']
        })
        _schema.properties.user_type.readOnly = true;
    }


    if (mode === 'files' || mode === 'profile' || userType === 'member') {
        delete _schema.properties.allow_tip;
        delete _schema.properties.employee_type;
        delete _schema.properties.allow_mobile_app_access;
        _schema.properties.employee_percent.readOnly = true;
        _schema.properties.hourly_rate.readOnly = true;
    }

    if (!config) return _schema;

    if (config.client.data.roles && config.client.data.roles.length) {
        const employee_roles = config.client.data.roles.map((role) => {
            return {
                title: role.name,
                enum: [role.code]
            }
        });

        employee_roles.unshift({
            title: 'Others',
            enum: ['z_none']
        })

        _schema.properties.employee_type = {
            type: 'string',
            title: 'Employee role',
            anyOf: employee_roles,
            default: 'z_none'
        }
    }
    else {
        const employee_roles = [
            {
                title: 'Others',
                enum: ['z_none']
            }
        ]

        _schema.properties.employee_type = {
            type: 'string',
            title: 'Employee role',
            anyOf: employee_roles,
            default: 'z_none'
        }
    }

    if (currentEntityUser && currentEntityUser.payment_mode === 'hourly') {
        _schema.properties.expected_fixed_hours.readOnly = true;
    }

    if (config?.integration_details?.product !== 'tripletex') {
        delete _schema.properties.departmentIntegration;
    }

    if (config?.client?.data?.basicRules?.hideSensitiveDetailsFromPM && currentUser?.user_type === 'pm' && currentUser?.id !== currentEntityUser?.id) {
        _schema.properties.social_number.readOnly = true;
        _schema.properties.bank_account_number.readOnly = true;
        _schema.properties.hourly_rate.readOnly = true;
        _schema.properties.calculated_weekly_hourly_payment.readOnly = true;
        _schema.properties.hourly_rate.type = ['string', 'null'];
        _schema.properties.fixed_hourly_rate.type = ['string'];
        _schema.properties.calculated_weekly_hourly_payment.type = ['string'];
        delete _schema.properties.fixed_hourly_rate.default;
        delete _schema.properties.calculated_weekly_hourly_payment.default;
        delete _schema.properties.social_number.format;
    }

    if (mode === 'superadmins' || userType === 'super-admin') {
        delete _schema.properties.address;
        delete _schema.properties.post_number;
        delete _schema.properties.post_place;
        delete _schema.properties.user_type;
        delete _schema.properties.allow_tip;
        delete _schema.properties.employee_type;
        delete _schema.properties.timelog_start_from;
        delete _schema.properties.employee_percent;
        delete _schema.properties.payment_mode;
        delete _schema.properties.hourly_rate;
        delete _schema.properties.vacation_days;
        delete _schema.properties.allow_mobile_app_access;
        delete _schema.properties.bank_account_number;
        delete _schema.properties.extra_payments;
        delete _schema.properties.groups;
        delete _schema.properties.expected_fixed_hours;
        delete _schema.properties.weekend_extra_payment_percent;
        delete _schema.properties.calculated_weekly_hourly_payment;
        delete _schema.properties.payment_cycle;
        delete _schema.properties.fixed_hourly_rate;
        _schema.properties.user_type = {
            type: 'string',
            title: 'User type',
            default: 'super-admin',
            anyOf: [
                {
                    title: 'Super-admin',
                    enum: ['super-admin'],
                },
            ],
        }
    }

    return _schema;
};

export default getSchema;
