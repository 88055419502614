import {v4 as uuid} from 'uuid';
//components
import Clients from './containers/Admin/Clients/Clients';
import Dashboard from './containers/Admin/Dashboard';
import {
    PayrollReport,
    VacationReport,
    WeeklyReport,
    EmployeeProjectReport, 
    ProjectReport,
} from './containers/Admin/Reports/Reports';
import CompanyProfile from './containers/Admin/Clients/CompanyProfile';
import SendReport from './containers/Admin/SendReport/SendReport';
import RestaurantReport from './containers/Admin/Reports/RestaurantReport';
import Chat from './containers/Admin/Chat/Chat';
import Deviation from './containers/Admin/Deviation/Deviation';
import Checklist from './containers/Admin/Checklist/Checklist';
import Documents from './containers/Admin/Document/Document';
import Visitors from './containers/Admin/Visitors/VisitorTable';
import VisitorAdd from './containers/Admin/Visitors/VisitorAdd';
import ControlPanelv2 from '~/containers/Admin/ControlPanel/ControlPanel';
import SuperAdmins from '~/containers/Admin/Users/SuperAdmins';
import BizTypesPage from '~/containers/Admin/BizTypes/BizTypes';
import BizTypesForm from '~/containers/Admin/BizTypes/BizTypesForm';
import Profile from '~/containers/Admin/Users/Profile';
import Projects from '~/containers/Admin/Projects/Projects';
import Members from '~/containers/Admin/Users/Members';
import Tasks from '~/containers/Admin/Tasks/Tasks';
import Timelogs from '~/containers/Admin/Timelogs/Timelogs';
import TipList from './containers/Admin/Tips/TipList';
import ScheduleMain from '~/containers/Admin/Schedule/ScheduleMain';
import ExtraDocPage  from '~/containers/Admin/ExtraDocs/ExtraDocTabs';
import EmployeeBoard from '~/containers/Admin/EmployeeBoard/EmployeeBoard';
import Absences from '~/containers/Admin/Absences/Absences';
import Vacations from '~/containers/Admin/Vacations/Vacations';
import DocProgress from './containers/Admin/SignDocs/DocProgress';
import DocSuccess from './containers/Admin/SignDocs/DocSuccess';
import SignDocHistory from './containers/Admin/SignDocs/SignDocHistory';
import CaseList from './containers/Admin/SignDocs/CaseList';
import ContractsNew from './containers/Admin/Contracts/ContractsNew';
import ContractsForm from './containers/Admin/Contracts/ContractsForm';
import ContractsEditor from './containers/Admin/Contracts/contractsEditor';
import EmployeeContract from './containers/Admin/Contracts/employeeContract';
import EditContracts from './containers/Admin/DocContracts/EditContracts';
import Monitoring from './containers/Admin/Monitoring/Monitoring';
import MonitoringForm from './containers/Admin/Monitoring/MonitoringForm';
import MonitoringGrid from './containers/Admin/Monitoring/MonitoringGrid';
import MonitoringHistory from './containers/Admin/Monitoring/MonitoringHistory';
import Subscriptions from './containers/Admin/Subscriptions/Subscriptions';
import TimelogsForm from './containers/Admin/Timelogs/TimelogsForm';
import ProjectsForm from './containers/Admin/Projects/ProjectsForm';
import TasksForm from './containers/Admin/Tasks/TasksForm';
import DeviationForm from './containers/Admin/Deviation/DeviationForm';
import SubscriptionsForm from './containers/Admin/Subscriptions/SubscriptionsForm';
import ExtraDocForm from './containers/Admin/ExtraDocs/ExtraDocForm';
import ClientsForm from './containers/Admin/Clients/ClientsForm';
import VacationsForm from './containers/Admin/Vacations/VacationsForm';
import AbsencesForm from './containers/Admin/Absences/AbsencesForm';

//utils
import customerFeature from '~/utils/customerFeature';

//assets
import Home from './assets/img/home.svg';
import Setting from './assets/img/setting.svg';
import BusinessTypeIcon from './assets/img/business-time-solid.svg';
import visitorIcon from './assets/img/profile-2user.svg';
import Time from './assets/img/time-calender.svg';
import Project from './assets/img/job.svg';
import Report from './assets/img/monitor-mobbile.svg';
import ChatIcon from './assets/img/message-text.svg';
import TipsIcon from './assets/img/calculator.svg';

// "pm_hide_tasks":true, "pm_hide_vacations":true, "pm_hide_absences":true, "pm_no_register":true

const routes = [
    {
        collapse: true,
        collapseId: uuid(),
        name: 'Time',
        icon: Time,
        views: [
            {
                path: '/schedule',
                layout: '/admin',
                name: 'Schedule',
                component: ScheduleMain,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;
                    if (customerFeature('union_group')) return false;
                    if (
                        (user.user_type === 'admin' || user.user_type === 'pm')
                        && customerFeature('admin_hide_absences')
                    ) return false;
                    return true;
                },
            },
            {
                path: '/timelogs',
                layout: '/admin',
                name: 'Working hours',
                component: Timelogs,
                allowed: user => user.user_type !== 'super-admin',
                hasChildRoutes:true,
                childRoutes:[
                    {
                        path: '/timelogs/add',
                        layout: '/admin',
                        name: 'Register Working hours',
                        component: TimelogsForm,
                        allowed: user => user.user_type !== 'super-admin',
                    },
                    {
                        path: '/timelogs/:id',
                        layout: '/admin',
                        name: 'Update Working hours',
                        component: TimelogsForm,
                        allowed: user => user.user_type !== 'super-admin',
                    }
                ]
            },            
            {
                path: '/absences',
                layout: '/admin',
                name: 'Absents',
                component: Absences,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;
                    if (!customerFeature('allow_absents')) return false;
                    if (user.user_type === 'admin' && customerFeature('admin_hide_absences')) return false;
                    if (
                        (user.user_type === 'admin' || user.user_type === 'pm')
                        && customerFeature('pm_allow_editing_all')
                    ) return true;
                    if (user.user_type !== 'member' && customerFeature('pm_hide_absences')) return false;
                    return true;
                },
                hasChildRoutes:true,
                childRoutes:[
                    {
                        path: '/absences/add',
                        layout: '/admin',
                        name: 'Add Absences',
                        component: AbsencesForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;
                            if (!customerFeature('allow_absents')) return false;
                            if (user.user_type === 'admin' && customerFeature('admin_hide_absences')) return false;
                            if (
                                (user.user_type === 'admin' || user.user_type === 'pm')
                                && customerFeature('pm_allow_editing_all')
                            ) return true;
                            if (user.user_type !== 'member' && customerFeature('pm_hide_absences')) return false;
                            return true;
                        },
                    },
                    {
                        path: '/absences/:id',
                        layout: '/admin',
                        name: 'Update Absences',
                        component: AbsencesForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;
                            if (!customerFeature('allow_absents')) return false;
                            if (user.user_type === 'admin' && customerFeature('admin_hide_absences')) return false;
                            if (
                                (user.user_type === 'admin' || user.user_type === 'pm')
                                && customerFeature('pm_allow_editing_all')
                            ) return true;
                            if (user.user_type !== 'member' && customerFeature('pm_hide_absences')) return false;
                            return true;
                        },
                    }
                ]
            },
            {
                path: '/vacations',
                layout: '/admin',
                name: 'Vacations',
                component: Vacations,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;
                    if (!customerFeature('allow_vacations')) return false;
                    if (user.user_type === 'admin' && customerFeature('admin_hide_absences')) return false;
                    if (
                        (user.user_type === 'admin' || user.user_type === 'pm')
                        && customerFeature('pm_allow_editing_all')
                    ) return true;
                    if (user.user_type !== 'member' && customerFeature('pm_hide_vacations')) return false;
                    return true;
                },
                hasChildRoutes: true,
                childRoutes: [
                    {
                        path: '/vacations/add',
                        layout: '/admin',
                        name: 'Add Vacations',
                        component: VacationsForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;
                            if (!customerFeature('allow_vacations')) return false;
                            if (user.user_type === 'admin' && customerFeature('admin_hide_absences')) return false;
                            if (
                                (user.user_type === 'admin' || user.user_type === 'pm')
                                && customerFeature('pm_allow_editing_all')
                            ) return true;
                            if (user.user_type !== 'member' && customerFeature('pm_hide_vacations')) return false;
                            return true;
                        },
                    },
                    {
                        path: '/vacations/:id',
                        layout: '/admin',
                        name: 'Update Vacations',
                        component: VacationsForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;
                            if (!customerFeature('allow_vacations')) return false;
                            if (user.user_type === 'admin' && customerFeature('admin_hide_absences')) return false;
                            if (
                                (user.user_type === 'admin' || user.user_type === 'pm')
                                && customerFeature('pm_allow_editing_all')
                            ) return true;
                            if (user.user_type !== 'member' && customerFeature('pm_hide_vacations')) return false;
                            return true;
                        },
                    }
                ]
            },
            {
                path: '/members',
                layout: '/admin',
                name: 'Users',
                icon: visitorIcon,
                component: Members,
                allowed: user => user.user_type === 'admin' || user.user_type === 'pm',                     
            },
            {
                path: '/tips',
                layout: '/admin',
                name: 'Tips',
                icon: TipsIcon,
                component: TipList,
                allowed: (user, isTipAllowed) =>  (user.user_type === 'pm' && isTipAllowed),
            }
        ],
    },    
    {
        collapse: true,
        collapseId: uuid(),
        name: 'Projects',
        icon: Project,
        views: [
            {
                path: '/projects',
                layout: '/admin',
                name: 'Projects',
                component: Projects,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;
                    if (user.user_type === 'member' && !customerFeature('member_allow_see_projects')) return false;
                    return true;
                },
                hasChildRoutes:true,
                childRoutes:[
                    {
                        path: '/Projects/add',
                        layout: '/admin',
                        name: 'Add Project',
                        component: ProjectsForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;
                            if (user.user_type === 'member') return false;
                            return true;
                        }
                    },
                    {
                        path: '/Projects/:id',
                        layout: '/admin',
                        name: 'Update Project',
                        component: ProjectsForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;
                            if (user.user_type === 'member') return false;
                            return true;
                        }
                    }
                ]
            },
            {
                path: '/tasks',
                layout: '/admin',
                name: 'Tasks',
                component: Tasks,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;                    
                    return true;
                },
                hasChildRoutes:true,
                childRoutes:[
                    {
                        path: '/tasks/add',
                        layout: '/admin',
                        name: 'Add tasks',
                        component: TasksForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;                    
                            return true;
                        },
                    },
                    {
                        path: '/tasks/:id',
                        layout: '/admin',
                        name: 'Update tasks',
                        component: TasksForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;                    
                            return true;
                        },
                    }
                ]
            },
        ],
    },    
    {
        collapse: true,
        collapseId: uuid(),
        name: 'Control',
        icon: Setting,
        views: [
            {
                path: '/deviation',
                layout: '/admin',
                name: 'Deviation',
                component: Deviation,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;
                    if (!customerFeature('enable_deviations')) return false;
                    if (user.user_type === 'admin' && customerFeature('union_group')) return false;
                    return true;
                },
                hasChildRoutes:true,
                childRoutes:[
                    {
                        path: '/deviation/add',
                        layout: '/admin',
                        name: 'Add Deviation',
                        component: DeviationForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;
                            if (!customerFeature('enable_deviations')) return false;
                            if (user.user_type === 'admin' && customerFeature('union_group')) return false;
                            return true;
                        },
                    },
                    {
                        path: '/deviation/:id',
                        layout: '/admin',
                        name: 'Update Deviation',
                        component: DeviationForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;
                            if (!customerFeature('enable_deviations')) return false;
                            if (user.user_type === 'admin' && customerFeature('union_group')) return false;
                            return true;
                        },
                    }
                ]
            },
            {
                path: '/checklist',
                layout: '/admin',
                name: 'Checklist',
                component: Checklist,
                allowed: user => {
                 if (user.user_type === 'super-admin') return false;
                    // if (!customerFeature('enable_deviations')) return false;
                    // if (user.user_type === 'admin' && customerFeature('union_group')) return false;
                    return true;
                }, // disable to hide it from
            },            
            {
                path: '/documents/templates',
                layout: '/admin',
                name: 'Documents',
                component: Documents,
                allowed: user => {return true;},
                hasChildRoutes:true,
                childRoutes:[
                    {
                        path: '/documents',
                        layout: '/admin',
                        name: 'Documents',
                        component: Documents,
                        allowed: user => {return true;},
                    },
                    {
                        path: '/documents/add',
                        layout: '/admin',
                        name: 'Add documents',
                        component: Documents,
                        allowed: user => {return true;},
                    },
                    {
                        path: '/documents/:id',
                        layout: '/admin',
                        name: 'Update documents',
                        component: Documents,
                        allowed: user => {return true;},
                    } ,
                    {
                        path: '/documents/templates/add',
                        layout: '/admin',
                        name: 'Add documents templates',
                        component: Documents,
                        allowed: user => {return true;},
                    },
                    {
                        path: '/documents/templates/:id',
                        layout: '/admin',
                        name: 'Add documents templates',
                        component: Documents,
                        allowed: user => {return true;},
                    }                    
                ]            
            }, 
                     
            {
                path: '/internal',
                layout: '/admin',
                name: 'Internal documents',
                component: ExtraDocPage,
                hide: true,
                allowed: user => true,
                hasChildRoutes:true,
                childRoutes:[
                    {
                        path: '/internal/add',
                        layout: '/admin',
                        name: 'Add internal docs',
                        component: ExtraDocForm,
                        allowed: user => {return true;},
                    },
                    {
                        path: '/internal/:id',
                        layout: '/admin',
                        name: 'Update internal docs',
                        component: ExtraDocForm,
                        allowed: user => {return true;},
                    }
                ]

            },
            {
                path: '/contracts',
                layout: '/admin',
                name: 'Contracts',
                component: ExtraDocPage,
                hide: true,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;
                    if (customerFeature('union_group')) return false;
                    return true;
                },                 
                hasChildRoutes:true,
                childRoutes:[
                    {
                        path: '/contracts/add',
                        layout: '/admin',
                        name: 'Add contracts',
                        component: ExtraDocForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;
                            if (customerFeature('union_group')) return false;
                            return true;
                        },
                    },
                    {
                        path: '/contracts/:id',
                        layout: '/admin',
                        name: 'Update contracts',
                        component: ExtraDocForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;
                            if (customerFeature('union_group')) return false;
                            return true;
                        },
                    }
                ]
            },
            {
                path: '/monitor',
                layout: '/admin',
                name: 'Monitoring',
                component: Monitoring,
                hide: false,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;
                    return true;
                },
                hasChildRoutes:true,
                childRoutes:[
                    {
                        path: '/monitor/add',
                        layout: '/admin',
                        name: 'Add monitor',
                        component: MonitoringForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;
                            return true;
                        },
                    },
                    {
                        path: '/monitor/:id',
                        layout: '/admin',
                        name: 'Update monitor',
                        component: MonitoringForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;                    
                            return true;
                        },
                    },
                    {
                        path: '/monitor/addGrid',
                        layout: '/admin',
                        name: 'Add monitor grid',
                        component: MonitoringGrid,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;                    
                            return true;
                        },
                    },
                    {
                        path: '/monitor/addGrid/:id',
                        layout: '/admin',
                        name: 'Update monitor grid',
                        component: MonitoringGrid,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;                    
                            return true;
                        },
                    },
                    {
                        path: '/monitor/viewGrid/:id',
                        layout: '/admin',
                        name: 'View monitor grid',
                        component: MonitoringHistory,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;                    
                            return true;
                        },
                    }
                ]
            },
            {
                path: '/subscriptions',
                layout: '/admin',
                name: 'Reminder',
                component: Subscriptions,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;                    
                    return true;
                }, 
                hasChildRoutes:true,
                childRoutes:[
                    {
                        path: '/subscriptions/add',
                        layout: '/admin',
                        name: 'Add subscription',
                        component: SubscriptionsForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;                    
                            return true;
                        },
                    },
                    {
                        path: '/subscriptions/:id',
                        layout: '/admin',
                        name: 'Update subscription',
                        component: SubscriptionsForm,
                        allowed: user => {
                            if (user.user_type === 'super-admin') return false;                    
                            return true;
                        },
                    }
                ]
            },
            {
                path: '/cases',
                layout: '/admin',
                name: 'Digital Signature',
                component: CaseList,
                hide:false,
                allowed: (user)=> user.user_type !== 'super-admin'
            }                                    
        ],
    },
    {
        path: '/chat',
        layout: '/admin',
        name: 'Chat',
        icon: ChatIcon,
        component: Chat,
        allowed: user => user.user_type !== 'super-admin',
        hasChildRoutes:true,
        childRoutes:[
           
            {
                path: '/chat/:id',
                layout: '/admin',
                name: 'Update chat',
                component: Chat,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;                    
                    return true;
                },
            }
        ]
    },  
    {
        path: '/privatechat',
        layout: '/admin',
        name: 'Chat',
        icon: ChatIcon,
        hide: true,
        component: Chat,
        allowed: user => user.user_type !== 'super-admin',
        hasChildRoutes:true,
        childRoutes:[
            
            {
                path: '/privatechat/:id',
                layout: '/admin',
                name: 'Update private chat',
                component: Chat,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;                    
                    return true;
                },
            }
        ]
    },   
    {
        path:'/docedit',
        layout:'/admin',
        name:'contract',
        hide: true,
        component: EditContracts,
        allowed: user => user.user_type === 'admin', // disable to hide it from
    },
    {
        path: '/rules',
        layout: '/admin',
        name: 'Rules',
        icon: 'pe-7s-info',
        url: 'RULES',
        hide:true,
        allowed: user => customerFeature('union_group'), // disable to hide it from
    },    
    {
        path: '/presence_view',
        layout: '/admin',
        name: 'Employee board',
        icon: 'pe-7s-wallet',
        component: EmployeeBoard,
        allowed: user => false, // user => false && !!((user.user_type === 'admin' || user.user_type === 'pm') && customerFeature('presence_view')),
    },
    {
        path: '/clients',
        layout: '/admin',
        name: 'Clients',
        icon: Report,
        component: Clients,
        allowed: user => user.user_type === 'super-admin',
        hasChildRoutes: true,
        childRoutes: [
            {
                path: '/clients/add',
                layout: '/admin',
                name: 'Create Clients',
                component: ClientsForm,
                allowed: user => user.user_type === 'super-admin',
            },
            {
                path: '/clients/:id',
                layout: '/admin',
                name: 'Update Clients',
                component: ClientsForm,
                allowed: user => user.user_type === 'super-admin',
            }
        ],
    },
    {
        path: '/biztypes',
        layout: '/admin',
        name: 'Business Types',
        icon: BusinessTypeIcon,
        component: BizTypesPage,
        allowed: user => user.user_type === 'super-admin',
        hasChildRoutes:true,
        childRoutes:[
            {
                path: '/biztypes/add',
                layout: '/admin',
                name: 'Add Business Types',
                component: BizTypesForm,
                allowed: user => {
                    if (user.user_type === 'super-admin') return true;                    
                    return false;
                },
            },
            {
                path: '/biztypes/:id',
                layout: '/admin',
                name: 'Update Business Types',
                component: BizTypesForm,
                allowed: user => {
                    if (user.user_type === 'super-admin') return true;                    
                    return false;
                },
            }
        ]
    },    
    {
        path: '/control_panel',
        layout: '/admin',
        name: 'Controls',
        icon: 'pe-7s-car',
        component: ControlPanelv2,
        hide: true,
        allowed: user => user.user_type === 'admin',
    },
    {
        path:'/employeecontract',
        layout:'/admin',
        icon: 'pe-7s-paperclip',
        name:'Contract',
        hide: true,
        component: EmployeeContract,
        allowed: user => user.user_type === 'admin', // disable to hide it from
    },    
    {
        path: '/superadmins',
        layout: '/admin',
        name: 'Super admins',
        icon: Setting,
        component: SuperAdmins,
        allowed: user => user.user_type === 'super-admin',
        hasChildRoutes: false,
    },
    {
        path: '/contract_templates',
        layout: '/admin',
        name: 'Contract Templates',
        icon: Report,
        component: ContractsNew,
        allowed: user => user.user_type === 'super-admin',
    },
    {
        path: '/contractsform',
        layout: '/admin',
        name: 'ContractsForm',
        component: ContractsForm,
        hide: true,
        allowed: user => user.user_type === 'super-admin',
        hasChildRoutes:true,
                childRoutes:[
                    {
                        path: '/contractsform/:id',
                        layout: '/admin',
                        name: 'Update ContractsForm',
                        component: ContractsForm,
                        allowed: user => user.user_type === 'super-admin',
                    }
                ]
    },
    {
        path: '/contractseditor',
        layout: '/admin',
        name: 'ContractsEditor',
        component: ContractsEditor,
        hide: true,
        allowed: user => user.user_type === 'super-admin',
    },
    {
        path: '/send_report',
        layout: '/admin',
        name: 'Send Report',
        icon: 'pe-7s-paperclip',
        component: SendReport,
        hide: true,
        allowed: () => true, // disable to hide it from
    },
    // {
    //     path: '/locked',
    //     layout: '/admin',
    //     name: 'Stamping mode',
    //     icon: Project,
    //     component: LockedMode,
    //     allowed: user => !customerFeature('union_group')
    //         && (user.user_type === 'pm' || user.user_type === 'admin')
    //         && customerFeature('locked_mode'), // disable to hide it from
    // },
    {
        path: '/profile',
        layout: '/admin',
        name: 'Profile',
        icon: 'pe-7s-settings',
        component: Profile,
        hide: true,
        allowed: () => true, // disable to hide it from
    },
    {
        path: '/files',
        layout: '/admin',
        name: 'My Files',
        icon: 'pe-7s-settings',
        component: ExtraDocPage,
        hide: true,
        allowed: () => true, // disable to hide it from
    },
    {
        path: '/companyprofile',
        layout: '/admin',
        name: 'Company profile',
        icon: 'pe-7s-settings',
        component: CompanyProfile,
        hide: true,
        allowed: user => user.user_type === 'admin', // disable to hide it from
    },
    {
        collapse: true,
        collapseId: uuid(),
        name: 'Reports',
        icon: Report,
        views: [
            {
                path: '/reports/payroll',
                layout: '/admin',
                name: 'Payroll report',
                component: PayrollReport,
                allowed: user => user.user_type !== 'super-admin', // disable to hide it from
            },
            // {
            //     path: '/reports/dailylog',
            //     layout: '/admin',
            //     name: 'Daily Log Report',
            //     component: DailyLogReport,
            //     allowed: user => user.user_type !== 'super-admin', // disable to hide it from
            // },
            {
                path: '/reports/vacation',
                layout: '/admin',
                name: 'Vacation report',
                component: VacationReport,
                allowed: user => user.user_type !== 'super-admin', // disable to hide it from
            },
            // {
            //     path: '/reports/associate',
            //     layout: '/admin',
            //     name: 'Invoice report',
            //     component: AssociateReport,
            //     allowed: user => !customerFeature('union_group') && user.user_type !== 'super-admin', // disable to hide it from
            // },
            {
                path: '/reports/rest',
                layout: '/admin',
                name: 'User report',
                component: RestaurantReport,
                allowed: user => !customerFeature('union_group')
                    && customerFeature('rest_report')
                    && (user.user_type === 'pm' || user.user_type === 'admin'), // disable to hide it from
            },
            // {
            //     path: '/reports/gps',
            //     layout: '/admin',
            //     name: 'GPS report',
            //     component: GpsReport,
            //     allowed: user => customerFeature('gps_enabled') && user.user_type !== 'super-admin',
            // },
            {
                path: '/reports/weekly',
                layout: '/admin',
                name: 'Weekly report',
                component: WeeklyReport,
                allowed: user => !customerFeature('union_group')
                    && customerFeature('reports_weekly')
                    && user.user_type !== 'super-admin', // disable to hide it from
            },
            {
                path: '/reports/project',
                layout: '/admin',
                name: 'Project Report',
                component: ProjectReport,
                allowed: user => user.user_type === 'pm' || user.user_type === 'admin'
            },
            // {
            //     path: '/reports/employee',
            //     layout: '/admin',
            //     name: 'Employee report',
            //     component: EmployeeReport,
            //     allowed: user => (!customerFeature('union_group') && user.user_type === 'admin') || user.user_type === 'pm',
            // },
            {
                path: '/reports/employee_project',
                layout: '/admin',
                name: 'Employee Payroll report',
                component: EmployeeProjectReport,
                allowed: user => (!customerFeature('union_group') && user.user_type === 'admin') || user.user_type === 'pm',
            },
        ],
    },   
    {
        path: '/visitors',
        layout: '/admin',
        icon: visitorIcon,
        name: 'Visitors',
        component: Visitors,
        allowed: user => {
            if (user.user_type === 'super-admin') return true;            
            return false;
        },
    },
    {
        path: '/guest',
        layout: '/admin',
        icon: visitorIcon,
        name: 'Visitor Register',
        component: VisitorAdd,
        allowed: user => {
            if (user.user_type === 'super-admin') return false;
            if (user.user_type === 'member') return false;            
            return true;
        },
    },
    {
        path: '/signupload',
        layout: '/admin',
        icon: 'pe-7s-pen',
        name: 'Signature',
        hide:true,
        component: DocProgress,
        allowed: user => user.user_type !== 'super-admin'
    },    
    {
        path: '/docsuccess',
        layout: '/admin',
        hide: true,
        name: 'Digital Signature',
        component: DocSuccess,
        allowed: () => true,
    },
    {
        path: '/signdochistory',
        layout: '/admin',
        hide: true,
        name: 'Digital Singature',
        component: SignDocHistory,
        allowed: () => true,
    },
    {
        path: '/*',
        layout: '/admin',
        name: 'Dashboard',
        icon: Home,
        component: Dashboard,
        // element: <Dashboard />,
        order: 0.5,
        allowed: () => true,
    },
];
export default routes;