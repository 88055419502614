import ReactDOM from 'react-dom/client';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';

import App from './App';

import authStore from '~/library/stores/authStore';
import commonStore from '~/library/stores/commonStore';
import userStore from '~/library/stores/userStore';
import clientStore from '~/library/stores/clientStore';
import biztypeStore from '~/library/stores/biztypesStore';
import dashboardStore from '~/library/stores/dashboardStore';
import projectStore from '~/library/stores/projectStore';
import taskStore from '~/library/stores/taskStore';
import chatStore from '~/library/stores/chatStore';
import timelogStore from '~/library/stores/timelogStore';
import absenceStore from '~/library/stores/absenceStore';
import vacationStore from '~/library/stores/vacationStore';
import importStore from '~/library/stores/importStore';
import presenceStore from '~/library/stores/presenceStore';
import lockedStore from '~/library/stores/lockedStore';
import scheduleStore from '~/library/stores/scheduleStore';
import deviationStore from '~/library/stores/deviationStore';
import extradocStore from '~/library/stores/extradocStore';
import checklistStore from '~/library/stores/checklistStore';
import documentStore from '~/library/stores/documentStore';
import visitorStore from '~/library/stores/visitorStore';
import recipientStore from '~/library/stores/recipientStore';
import contractStore from './library/stores/contractStore';
import signatureStore from '~/library/stores/signatureStore';
import propertyStore from '~/library/stores/propertyStore';
import monitorStore from './library/stores/monitorStore';
import subscriptionStore from '~/library/stores/subscriptionStore';
import taskTemplateStore from '~/library/stores/taskTemplateStore';
import multiAccountAdminStore from '~/library/stores/multiAccountAdminStore';
import userGroupStore from '~/library/stores/userGroupStore';
import shiftAllowanceStore from '~/library/stores/shiftAllowanceStore';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/css/animate.min.css';
import './styles/css/pe-icon-7-stroke.css';
import './styles/css/fontawesome.5.15.free.web.all.css';
import './styles/scss/light-bootstrap-dashboard-pro-react.scss';

// Custom Styles
import './styles/scss/styles.scss';

import { reaction } from 'mobx';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import 'moment/locale/en-gb';
import 'moment/locale/nb';
import 'moment/locale/pl';
import 'moment/locale/lt';
import 'moment/locale/ru';
import 'moment/locale/es';
import 'moment/locale/sv';

import i18n from './i18n.js';

Sentry.init({ dsn: 'https://d4164e51d5e84c14aae19598ac581c48@sentry.io/1482192' });

const stores = {
    authStore,
    commonStore,
    userStore,
    clientStore,
    biztypeStore,
    dashboardStore,
    projectStore,
    taskStore,
    chatStore,
    timelogStore,
    absenceStore,
    checklistStore,
    vacationStore,
    importStore,
    presenceStore,
    lockedStore,
    scheduleStore,
    deviationStore,
    extradocStore,
    documentStore,
    visitorStore,
    recipientStore,
    signatureStore,
    propertyStore,
    contractStore,
    monitorStore,
    subscriptionStore,
    taskTemplateStore,
    multiAccountAdminStore,
    userGroupStore,
    shiftAllowanceStore
};

reaction(
    () => commonStore.locale,
    locale => {
        window.sessionStorage.setItem('locale', locale);
        let lang = 'en';
        if (locale === 'no') {
            lang = 'nb';
        }
        moment.locale(lang);
        i18n.changeLanguage(locale);
    }
);

// For easier debugging
window._____APP_STATE_____ = stores;


ReactDOM.createRoot( document.getElementById('root')).render(
    <Provider {...stores}>
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </I18nextProvider>
    </Provider>
);
