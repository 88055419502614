import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Container, Row, Col, Button, Offcanvas, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

//i18n
import Login from "../../containers/Auth/Login";
import ForgotPassword from "../../containers/Auth/ForgotPassword"
import SignUpForm from "../../containers/Auth/SignUpForm";
import LanguageDropdown from '../Common/LanguageDropdown';
//Components

//assets
import CloseIcon from '../../assets/images/CloseIcon.svg';
import SocialIcon from '../Social';
import logo from '../../assets/images/logoDark.svg';
import loginIcon from '../../assets/images/loginIcon.svg';
import signUpIcon from '../../assets/images/signUpIcon.svg';

//styles
import './styles.css';

const Header = inject(
    'authStore',
    'commonStore',
    'userStore'
)(
    observer((props) => {
        const [show, setShow] = useState(false);
        const [SignupModal, setSignupModal] = useState(false);
        const [LoginModal, setLoginModal] = useState(false);
        const [ForgotPwdModal, setForgotModal] = useState(false);
        const menuClose = () => setShow(false);
        const menuShow = () => setShow(true);

        const handleLoginBtnClick = () => {
            setLoginModal(true);
        };
        const handleSignupBtnClick = () => {
            setSignupModal(true);
        };
        const handleClose = () => {
            setLoginModal(false);
            setSignupModal(false);
            setForgotModal(false);
            if(props.closeModalCallBack) props.closeModalCallBack();
        };
        const handleBackToLogin = () => {
            setLoginModal(true);
            setSignupModal(false);
            setForgotModal(false);
        };
        const handleForgotPwdClick = () => {
            setForgotModal(true);
            setLoginModal(false);
        };

        useEffect(() => {
            if(props.isLoginClicked) handleBackToLogin();
        },[props.isLoginClicked]);

        return (
            <>
                <ToastContainer />
                <header className="mainHeader">
                    <Container>
                        <Row>
                            <Col xs={12} sm={12} md={{ span: 3 }} lg={2}>
                                <div className="hdrLogo">
                                    <Link to="/">
                                        <img src={logo} alt="logo img" />
                                    </Link>
                                </div>
                            </Col>
                            <Col
                                xs={{ span: 12, offset: 0 }}
                                sm={{ span: 12, offset: 0 }}
                                md={{ span: 8, offset: 1 }}
                                lg={{ span: 10, offset: 0 }}
                            >
                                <Row>
                                    <Col md={12} lg={7}>
                                        <div className="hdrMain mt-4">
                                            {/* <div className="mt-4"> */}
                                            <div className="hdrMenu d-none d-lg-inline-block ">
                                                <ul>
                                                    <li className={`${props.home}`}>
                                                        <Link to="/" title={props.t('Home')}>
                                                            {props.t('Home')}
                                                        </Link>
                                                    </li>
                                                    <li className={`${props.visitor}`}>
                                                        <Link
                                                            to="/visitor-register"
                                                            title={props.t('Visitor Register')}
                                                        >
                                                            {props.t('Visitor Register')}
                                                        </Link>
                                                    </li>
                                                    <li className={`${props.about}`}>
                                                        <Link to="/about-us" title={props.t('About Us')}>
                                                            {props.t('About Us')}
                                                        </Link>
                                                    </li>
                                                    <li className={`${props.features}`}>
                                                        <Link to="/features" title={props.t('Features')}>
                                                            {props.t('Features')}
                                                        </Link>
                                                    </li>
                                                    <li className={`${props.contact}`}>
                                                        <Link to="/contact-us" title={props.t('Contact')}>
                                                            {props.t('Contact')}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12} lg={5} className="g-0 mt-2">
                                        <Row className="mb-4 justify-content-center justify-content-md-end mt-4">
                                            <Col
                                                xs={{ span: 4, order: 1 }}
                                                sm={{ span: 3, order: 1 }}
                                                md={{ span: 2, order: 1 }}
                                                lg={{ span: 3, order: 1 }}
                                            >
                                                <LanguageDropdown />
                                            </Col>
                                            <Col
                                                xs={{ span: 12, order: 3 }}
                                                sm={{ span: 6, order: 1 }}
                                                md={{ span: 8, order: 1 }}
                                                lg={{ span: 9, order: 1 }}
                                                className="gx-1"
                                            >
                                                <div className="menuBtn ml-10">
                                                    {!props.userStore.currentUser && (
                                                        <Col
                                                            xs={6}
                                                            className="d-flex justify-content-end justify-content-sm-center"
                                                        >
                                                            <Link
                                                                className="btnCommon btnTrans d-flex"
                                                                title={props.t('Login')}
                                                                onClick={() => handleLoginBtnClick()}
                                                            >
                                                                <i className="d-inline-block px-2">
                                                                    <img src={loginIcon} alt="login icon"/>
                                                                </i>
                                                                <span>{props.t('Login')}</span>
                                                            </Link>
                                                        </Col>
                                                    )}
                                                    {!props.userStore.currentUser && (
                                                        <Col xs={6}>
                                                            <Link
                                                                className="btnCommon btnTrans ml-10 d-flex"
                                                                title={props.t('Sign Up')}
                                                                onClick={() => handleSignupBtnClick()}
                                                            >
                                                                <i className="d-inline-block px-2">
                                                                    <img src={signUpIcon} alt='login' width="25px" />
                                                                </i>
                                                                <span>{props.t('Sign Up')}</span>
                                                            </Link>
                                                        </Col>
                                                    )}
                                                    {props.userStore.currentUser && (
                                                        <Col xs={6}>
                                                            <Link
                                                                to="/admin/"
                                                                className="btnCommon btnTrans"
                                                                title={props.t('Dashboard')}
                                                            >
                                                                <i className="fa fa-th-list" />
                                                                <span>{props.t('Dashboard')}</span>
                                                            </Link>
                                                        </Col>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col
                                                xs={{ span: 4, offset: 4, order: 2 }}
                                                sm={{ span: 3, offset: 0, order: 1 }}
                                                md={{ span: 2, order: 1 }}
                                            >
                                                <div className="offcanvasBtn d-lg-none">
                                                    <Button variant="menuBar" onClick={menuShow}>
                                                        <span className="bar1"></span>
                                                        <span className="bar2"></span>
                                                        <span className="bar3"></span>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* </div> */}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </header>
                <Offcanvas className="offcanvasOuter" show={show} onHide={menuClose}>
                    <Offcanvas.Header className="offcanvasHeader" closeButton>
                        <Offcanvas.Title>
                            <div className="offcanvasLogo">
                                <Link to="/">
                                    <img src={logo} alt="logo img" />
                                </Link>
                            </div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="offcanvasBody">
                        <div className="offcanvasBodyInner">
                            <div className="offcanvasTabsDiv">
                                <div className="hdrMenu">
                                    <ul>
                                        <li className="current">
                                            <Link to="/">{props.t('Home')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/visitor-register">{props.t('Visitor Register')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/about-us">{props.t('About Us')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/features">{props.t('Features')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact-us">{props.t('Contact')}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="hdrScl my-4">
                                <SocialIcon Icon="small" />
                            </div>
                            <div className="offcanvasCpyrt">
                                <p>
                                    © 2022 <Link to="/">{props.t('Time & Control')}</Link>.{' '}
                                    {props.t('All Rights Reserved')}.
                                </p>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
                <Modal show={LoginModal} onHide={handleClose} backdropClassName="dark-backdrop" className="login_modal">
                    <Modal.Header className="setHeader">
                        <Modal.Title className="fs22 loginTitle">{props.t('Login').toUpperCase()}</Modal.Title>
                        <div className="close-icon" onClick={() => handleClose()}>
                            <img src={CloseIcon} className="close-img" alt="Close Icon"></img>
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <Login />
                    </Modal.Body>
                    <Modal.Footer className="fs18 loginFooter" onClick={() => handleForgotPwdClick()}>
                        <button>{props.t('Forgot password')}?</button>
                    </Modal.Footer>
                </Modal>

                {/* forgot password modal */}
                <Modal
                    show={ForgotPwdModal}
                    onHide={handleClose}
                    backdropClassName="dark-backdrop"
                    className="login_modal"
                >
                    <Modal.Header className="setHeader">
                        <Modal.Title className="fs22 loginTitle">{props.t('Forgot password').toUpperCase()}</Modal.Title>
                        <div className="close-icon" onClick={() => handleClose()}>
                            <img src={CloseIcon} className="close-img" alt="Close Icon"></img>
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <ForgotPassword backToLogin={() => handleBackToLogin()} />
                    </Modal.Body>
                    <Modal.Footer className="fs18 loginFooter" onClick={() => handleBackToLogin()}>
                        <button>{props.t('Back to login')}</button>
                    </Modal.Footer>
                </Modal>

                {/* Sign Up Modal */}

                <Modal
                    show={SignupModal}
                    onHide={handleClose}
                    backdropClassName="dark-backdrop"
                    className="signup_modal"
                >
                    <Modal.Header className="setHeader">
                        <Modal.Title className="fs28 loginTitle">{props.t('Sign Up').toUpperCase()}</Modal.Title>
                        <div className="close-icon" onClick={() => handleClose()}>
                            <img src={CloseIcon} className="close-img" alt="Close Icon"></img>
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <SignUpForm />
                    </Modal.Body>
                    <Modal.Footer className="fs18 loginFooter" onClick={() => handleBackToLogin()}>
                        <button>{props.t('Back to login')}</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    })
);

export default withTranslation()(Header);
