import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import { NumberInput } from '../../../../elements/Input';
import SwitchWidget from '../SwitchWidget';

class CheckAvailability extends Component {
    state = {
        ruleForAvailability: {
            allowAvailability: true,
            allowAutoStatusAvailability: false,
            allowThreeHoursPriorScheduleNotification: false
        }
    }

    componentDidMount() {
          this.loadData();
      }

      loadData = () => {
          const { currentEntity } = this.props.clientStore;
          const { ruleForAvailability } = this.state;
          if (currentEntity.client.data && currentEntity.client.data.basicRules) {
              const basicRules = Object.assign({}, currentEntity.client.data.basicRules);
              ruleForAvailability.allowAvailability = basicRules.allowAvailability ? basicRules.allowAvailability : false;
              ruleForAvailability.allowAutoStatusAvailability = basicRules.allowAutoStatusAvailability ? basicRules.allowAutoStatusAvailability : false;
              ruleForAvailability.allowThreeHoursPriorScheduleNotification = basicRules.allowThreeHoursPriorScheduleNotification ? basicRules.allowThreeHoursPriorScheduleNotification : false;
              this.setState({ ruleForAvailability });
          }
      }

    handleSwitch = (name, evt) => {
        const { ruleForAvailability } = this.state;
        ruleForAvailability[name] = evt;
        this.props.handleUserDataChange(name, evt);
        this.setState({ ruleForAvailability })
    }

    render() {
        const { t } = this.props;
        const { ruleForAvailability } = this.state
        return (
            <div>
                <Row style={{ marginTop: '2rem' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={ruleForAvailability.allowAvailability}
                            onChange={checked => {
                                if(checked == false) this.handleSwitch('allowAutoStatusAvailability', checked)
                                this.handleSwitch('allowAvailability', checked)}
                            } />
                    </Col>
                    <Col sm={10}>
                        <label className="control-label">{t('Activate availability functionality in shift schedule')}.</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("By clicking on this, the accessibility module will be activated. New buttons appear at the top right under the roster module")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                {ruleForAvailability.allowAvailability &&
                    <Row style={{ marginTop: '2rem' }}>
                        <Col sm={1}>
                            <SwitchWidget
                                value={ruleForAvailability.allowAutoStatusAvailability}
                                onChange={checked => this.handleSwitch('allowAutoStatusAvailability', checked)}
                             />
                        </Col>
                        <Col sm={10}>
                            <label className="control-label">{t('Enable automatic approval of availability')}</label>
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        {t("Administrator or PM does not need to approve registered availability, the system will automatically approve it and notify the member")}
                                    </Tooltip>
                                }
                            >
                                <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                }
                <Row style={{ marginTop: '2rem' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={ruleForAvailability.allowThreeHoursPriorScheduleNotification}
                            onChange={checked => {
                                this.handleSwitch('allowThreeHoursPriorScheduleNotification', checked)
                            }
                            } />
                    </Col>
                    <Col sm={10}>
                        <label className="control-label">{t('Activate 3 Hours Prior Notification of shifts')}.</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("System will send push notification to employee 3 hours prior of shifts")}.
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
            </div>
        )
    }

}
export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(CheckAvailability))));