import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Row, Table, OverlayTrigger, Tooltip, Tab, Badge } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '../../../hoc/withLocalization';
import InputTime from '../../../components/GenericForm/Widgets/InputTime';
import GPSInfo from './GPSInfo';
import TimePickerInput from '../../../components/TimePicker';
import SwitchWidget from '../ControlPanel/SwitchWidget';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Textarea } from '../../../elements/Input';
import LoadingSpinner from '~/elements/LoadingSpinner';
import CustomRadio from '../../../components/CustomRadio/CustomRadio'
import { Accordion, AccordionItem } from '../../../components/Accordion/Accordion';
import { NumberInput } from '../../../elements/Input';

//services
import agent from '../../../library/agent';

//utils
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import { timeFormat, shortDateFormat } from '~/utils/dateFormat';

//assets
import ResetApprovalIcon from '../../../assets/img/resetApprovalIcon.svg';
import ApprovalIcon from '../../../assets/img/ApprovalIcon.svg';
import RejectIcon from '../../../assets/img/RejectIcon.svg';
import SaveAndAcceptIcon from '../../../assets/img/SaveAndAcceptIcon.svg';

const addInviteToShiftsOptions = [{ value: 'group', label: 'Members with same role', title: 'Members with same role' },
{ value: 'available', label: 'All available members', title: 'Members with same role' },
{ value: 'manual', label: 'Select and send invites manually', title: 'Select and send invites manually' }]


class ModalAccept extends Component {
    constructor(props) {
        super(props);
        this.isShiftAllowanceEnabled = props?.commonStore?.config?.client?.data?.extraPayments?.some(ele => ele.code === 'shift_allowance' && ele.isActive) || false;
        this.isEmergencyShiftEnabled = props?.commonStore?.config?.client?.data?.extraPayments?.some(ele => ele.code === 'emergency_shifts' && ele.isActive) || false;
        this.state = {
            notes: '',
            showTimeEditAndComment: false,
            clientInfo: null,
            fromInput: '',
            toInput: '',
            breakInput: '',
            approvalTimelogsIsManual: false,
            fromChangedBy: null,
            previousFrom: null,
            toChangedBy: null,
            previousTo: null,
            breakChangedBy: null,
            previousBreak: null,
            isLoading: true,
            syncWithIntegration: false,
            showShifts: false,
            shiftDetails: [],
            validationMessage: '',
            hasInterSectingShifts: null,
            moveShiftsToOpen: true,
            updatedData: null,
            overtimes: {},
            overtimeArray: [],
            currentTab: 'shifts',
            shiftAllowance: {},
            isAlreadySynced: false
        };

    }
    convertUserTypeToText(userType) {
        switch (userType) {
            case 'member':
                return 'Member';
            case 'pm':
                return 'PM';
            case 'admin':
                return 'Admin';
            default:
                return userType ? `${userType}` : '';
        }
    }

    componentDidMount() {
        this.loadData();
    }

    getShiftDetails = async (start, end, userId, isModalView, isLoadShiftAllowance) => {
        const dateTimeRules = this.getDateTimeRules();
        const { commonStore, scheduleStore, t } = this.props;
        try {
            const paramsObj = {
                start: start,
                end: end,
                userID: userId,
                isModalView: isModalView
            }
            const shifts = await scheduleStore.loadList(paramsObj, dateTimeRules);
            if (isLoadShiftAllowance) {
                this.setState({ shiftAllowance: shifts.shiftAllowance });
            }
            return shifts.shifts.list;
        } catch (err) {
            console.error({ err })
            commonStore.addNotification(t(typeof err === 'string' ? err : 'Something went wrong'), null, 'error');
        }
    }

    async loadData() {
        const { absenceStore, timelogStore, params, vacationStore, commonStore, t } = this.props;
        const isIntegrationAvailable = !!(commonStore && commonStore.config && commonStore.config.integration_details && commonStore.config.integration_details.product);
        if (params.entity === 'absence') {
            const absence = await absenceStore.load(this.props.params.ids[0]);
            absence.absence['model_name'] = 'absence';
            let validationMsg = '';
            if (isIntegrationAvailable) {
                validationMsg = await timelogStore.validateTimelogWithIntegration(absence.absence);
            }
            if (absence.absence.leave_type === 'unpaid') {
                validationMsg = "Unable to send Unpaid Absence to the payroll system";
            }
            let shiftDetails = [];
            if (params.count && params.count === 1) shiftDetails = await this.getShiftDetails(absence.absence.from, absence.absence.to, absence.absence.user_id, true);
            this.setState({ notes: absence.absence.status_note, validationMessage: t(validationMsg), shiftDetails }, () => this.setState({ isLoading: false }));
            return;
        }
        if (params.entity === 'vacation') {
            const vacation = await vacationStore.load(params.ids[0]);
            vacation.vacation['model_name'] = 'vacation';
            let validationMsg = '';
            if (isIntegrationAvailable) {
                validationMsg = await timelogStore.validateTimelogWithIntegration(vacation.vacation);
            }
            if (vacation.vacation.vacation_type === 'nonpaid') {
                validationMsg = "Unable to send Unpaid vacation to the payroll system";
            }
            let shiftDetails = []
            if (params.count && params.count === 1) shiftDetails = await this.getShiftDetails(vacation.vacation.from, vacation.vacation.to, vacation.vacation.user_id, true);
            this.setState({ notes: vacation.vacation.status_note, validationMessage: t(validationMsg), shiftDetails }, () => this.setState({ isLoading: false }));
            return;
        }
        const firstItem = (timelogStore?.currentList?.rows?.filter((row) => row.id === params.ids[0])[0]) || {};
        if (
            (params.count === 1 || params.count === '1') &&
            firstItem.validateTimetrackOption &&
            params.startedByWorklogButton
        ) {
            const { data } = firstItem;
            const clientInfo = commonStore.config;
            let fromChangedBy, toChangedBy, breakChangedBy;

            if (data.fromChangedById || data.toChangedById || data.breakChangedById) {
                const user = await agent.Users.load(data.fromChangedById)
                fromChangedBy = toChangedBy = breakChangedBy = user;
            }

            const shiftDetails = await this.getShiftDetails(firstItem.for_date, firstItem.for_date, firstItem.user_id, true, true);
            firstItem['model_name'] = 'timelog';
            let validateTimelogWithIntegration = '';
            if (isIntegrationAvailable) {
                validateTimelogWithIntegration = await timelogStore.validateTimelogWithIntegration(firstItem);
            }
            const isAlreadySynced = firstItem?.int_id && firstItem.int_status === 'success' ? true : false
            let overtimes = firstItem.data.overtimes;
            let overtimeArray = clientInfo.client.data.basicRules.overtime_types;
            if (clientInfo.client.data.basicRules.overtimeAutomaticMode) {
                const code = commonStore.config.client.data.basicRules.overtimeCalcRules.calc_percent;
                if (firstItem._total_hours_overtime) {
                    overtimes = {
                        [code]: {
                            code: `${clientInfo.client.data.basicRules.overtimeCalcRules.calc_percent}`,
                            value: minutesToTimeInput(firstItem._total_hours_overtime, false),
                            name: `Automatic Overtime ${clientInfo.client.data.basicRules.overtimeCalcRules.calc_percent}%`
                        }
                    }
                    overtimeArray = [overtimes[code]]
                }
            }
            this.setState({
                clientInfo,
                fromChangedBy: (fromChangedBy && fromChangedBy.user) || null,
                toChangedBy: (toChangedBy && toChangedBy.user) || null,
                breakChangedBy: (breakChangedBy && breakChangedBy.user) || null,
                previousFrom: data.previousFrom || data.previousFrom === 0 ? data.previousFrom : null,
                previousTo: data.previousTo || data.previousTo === 0 ? data.previousTo : null,
                previousBreak: data.previousBreak || data.previousBreak === 0 ? data.previousBreak : null,
                notes: firstItem.status_note || '',
                admin_note: firstItem.admin_note || '',
                fromInput: minutesToTimeInput(firstItem.from),
                toInput: minutesToTimeInput(firstItem.to),
                breakInput: minutesToTimeInput(firstItem.break || 0),
                approvalTimelogsIsManual: !!(
                    clientInfo &&
                    clientInfo.client &&
                    clientInfo.client.data &&
                    clientInfo.client.data.basicRules &&
                    clientInfo.client.data.basicRules.approvalTimelogs === 'manual'
                ),
                validationMessage: t(validateTimelogWithIntegration),
                shiftDetails: shiftDetails,
                syncWithIntegration: isAlreadySynced,
                isAlreadySynced: isAlreadySynced,
                overtimes: overtimes,
                overtimeArray: overtimeArray
            }, () => this.setState({ isLoading: false }));
        } else {
            this.setState({ isLoading: false })
        }
    }

    handleShift = async (action) => {
        const { t, commonStore } = this.props;
        if (action !== "approved" || !this.state.shiftDetails.length) {
            return this.saveAndSetStatus(action, { shifts: this.state.shiftDetails, moveShiftsToOpen: this.state.moveShiftsToOpen }).then(() => {
                commonStore.addNotification(t('Saved'), null, 'success');
            }).catch(err => {
                commonStore.addNotification(t(typeof err === 'string' ? err : 'Something went wrong'), null, 'error');
            });
        }
        const str = this.state.moveShiftsToOpen ?
            t("Move shifts to open")
            : t("Remove shifts");
        confirmAlert({
            title: this.props.t('Are You Sure') + "?",
            message: str,
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: async () => {
                        return this.saveAndSetStatus(action, { shifts: this.state.shiftDetails, moveShiftsToOpen: this.state.moveShiftsToOpen }).then(() => {
                            commonStore.addNotification(t('Saved'), null, 'success');
                        }).catch(err => {
                            commonStore.addNotification(t(typeof err === 'string' ? err : 'Something went wrong'), null, 'error');
                        });
                    },
                },
                {
                    label: this.props.t('No'),
                    onClick: () => { },
                },
            ],
        })
    }

    async handleRun(action) {
        if (action === 'rejected' && !this.state.notes) {
            this.props.commonStore.addNotification(this.props.t('Comment is required'), null, 'error');
            return;
        }
        const { params } = this.props;
        if (action === 'approved' && this.state.shiftDetails.length && (params.entity === 'absence' || params.entity === 'vacation')) {
            await this.handleShift(action);
        }
        else {
            this.saveAndSetStatus(action);
        }
    }

    saveAndSetStatus = async (action, shiftCreationParams) => {
        const { timelogStore, params, afterSave } = this.props;
        this.setState({ isLoading: true })
        for (let i = 0; i < params.ids.length; i++) {
            await timelogStore.setStatus(params.ids[i], params.entity, action, { notes: this.state.notes, admin_note: this.state.admin_note }, this.state.syncWithIntegration, shiftCreationParams);
        }
        if ((action === 'rejected' || action === 'approved') && params.entity === 'timelog') {
            if (params.ids.length === 1) {
                await timelogStore.singlePushNotification(params.ids[0], this.state.notes, action, false);
            } else {
                await timelogStore.massPushNotification(params.ids, this.state.notes, action);
            }
        }
        afterSave();
        if (this.state.syncWithIntegration) this.props.commonStore.addNotification(this.props.t(`Syncing complete! The timesheets is now synchronized with ${this.props.commonStore.config.integration_details.product}`), null, 'success');
        this.setState({ isLoading: false })
    }

    async saveOne() {
        const { timelogStore, userStore, params, afterSave } = this.props;
        const firstItem = timelogStore.currentList.rows.filter((row) => row.id === params.ids[0])[0];
        const newData = JSON.parse(JSON.stringify(firstItem.data));
        const userId = userStore.currentUser.id;
        const previousFrom = firstItem.from;
        const previousTo = firstItem.to;
        const previousBreak = firstItem.break;
        firstItem.from = getMinutesFromTimeInput(this.state.fromInput);
        firstItem.to = getMinutesFromTimeInput(this.state.toInput);
        firstItem.break = getMinutesFromTimeInput(this.state.breakInput);
        firstItem.total_hours_normal =
            getMinutesFromTimeInput(this.state.toInput) -
            getMinutesFromTimeInput(this.state.fromInput) -
            getMinutesFromTimeInput(this.state.breakInput);
        newData.fromChangedById = userId;
        newData.previousFrom = previousFrom;
        newData.toChangedById = userId;
        newData.previousTo = previousTo;
        newData.breakChangedById = userId;
        newData.previousBreak = previousBreak;
        firstItem.data = newData;
        await timelogStore.saveAndOverrideCurrentEntity(firstItem, false);
        afterSave();
    }

    async saveOneAndAccept() {
        const { timelogStore, userStore, params, afterSave, commonStore, t } = this.props;
        const firstItem = timelogStore.currentList.rows.filter((row) => row.id === params.ids[0])[0];
        if (!firstItem.description) {
            this.props.commonStore.addNotification(
                this.props.t(
                    'The worklog does not have a comment, unable to save the changes. Please fill in the comment to proceed'
                ),
                null,
                'error'
            );
            return;
        }
        const newData = JSON.parse(JSON.stringify(firstItem.data));
        const userId = userStore.currentUser.id;
        const previousFrom = firstItem.from;
        const previousTo = firstItem.to;
        const previousBreak = firstItem.break;
        firstItem.from = getMinutesFromTimeInput(this.state.fromInput);
        firstItem.to = getMinutesFromTimeInput(this.state.toInput);
        firstItem.break = getMinutesFromTimeInput(this.state.breakInput);
        if (firstItem.from > firstItem.to) {
            firstItem.total_hours_normal = 1440 -
                getMinutesFromTimeInput(this.state.fromInput) +
                getMinutesFromTimeInput(this.state.toInput) -
                getMinutesFromTimeInput(this.state.breakInput);
        }
        else {
            firstItem.total_hours_normal =
                getMinutesFromTimeInput(this.state.toInput) -
                getMinutesFromTimeInput(this.state.fromInput) -
                getMinutesFromTimeInput(this.state.breakInput);
        }
        newData.fromChangedById = userId;
        newData.previousFrom = previousFrom;
        newData.toChangedById = userId;
        newData.previousTo = previousTo;
        newData.breakChangedById = userId;
        newData.previousBreak = previousBreak;
        newData.status_notes = this.state.notes;
        firstItem.data = newData;
        firstItem.data.extraPayments = this.state.updatedData ? this.state.updatedData.extraPayments : newData.extraPayments;
        firstItem.status = 'approved';
        firstItem.status_note = this.state.notes;
        firstItem.admin_note = this.state.admin_note
        firstItem.status_changed_by = userId;
        firstItem.status_changed_on = new Date();
        firstItem.overrideClearStatusNote = true;
        firstItem.sendPushStatusNote = true;
        await timelogStore.saveAndOverrideCurrentEntity(firstItem, false);
        this.setState({ isLoading: true })
        const getMessage = await timelogStore.singlePushNotification(params.ids[0], this.state.notes, 'changed and approved', this.state.syncWithIntegration);
        commonStore.addNotification(t(getMessage.message), null, 'success');
        afterSave();
        this.setState({ isLoading: false })
    }

    isDataModified = () => {
        const { timelogStore, params } = this.props;
        if (!params || !params.ids || !params.ids.length) return false;
        const firstItem = timelogStore.currentList.rows.filter((row) => row.id === params.ids[0])[0];
        if (!firstItem) return true;
        if (this.state.updatedData?.extraPayments && firstItem.data?.extraPayments) {
            for (let i = 0; i < Object.keys(this.state.updatedData.extraPayments).length; i++) {
                for (let j = 0; j < Object.keys(firstItem.data.extraPayments).length; j++) {
                    if (Object.keys(this.state.updatedData.extraPayments)[i] === Object.keys(firstItem.data.extraPayments)[j]) {
                        if (Object.values(this.state.updatedData.extraPayments)[i].value !== Object.values(firstItem.data.extraPayments)[j].value) {
                            return true
                        }
                    }
                }
            }
        }
        return minutesToTimeInput(firstItem.from) !== this.state.fromInput || minutesToTimeInput(firstItem.to) !== this.state.toInput || minutesToTimeInput(firstItem.break) !== this.state.breakInput;
    }

    isStatusUpdated = () => {
        const { timelogStore, params } = this.props;
        if (!params || !params.ids || !params.ids.length || params.entity !== 'timelog') return true;
        const firstItem = timelogStore.currentList.rows.filter((row) => row.id === params.ids[0])[0];
        if (!firstItem) return true;
        return firstItem.status !== 'active';
    }

    handleSyncSwitch = (value) => {
        this.setState({ syncWithIntegration: value })
    }

    getDateTimeRules = () => {
        const { commonStore } = this.props;
        return commonStore.config.client && commonStore.config.client.data &&
            commonStore.config.client.data.dateTimeRules
            ? commonStore.config.client.data.dateTimeRules
            : false;
    }
    translateLabels = () => {
        const { t } = this.props;

        addInviteToShiftsOptions.forEach(item => {
            item.label = t(item.title)
        });
    }

    handleChangeExtraPayments = (extraPayment, value) => {
        const { timelogStore } = this.props;
        const updatedData = timelogStore.handleChangeExtraPayments(extraPayment, value, true)
        this.setState({ updatedData })
    };

    render() {
        this.translateLabels();

        if (this.state.isLoading)
            return <LoadingSpinner />;

        const { timelogStore, t, params, commonStore } = this.props;
        const { clientInfo, fromChangedBy, previousFrom, moveShiftsToOpen, currentTab, shiftAllowance,
            toChangedBy, previousTo, breakChangedBy, previousBreak, overtimes, overtimeArray } = this.state;

        if (!params) return null;
        const dateTimeRules = this.getDateTimeRules();

        const firstItem =
            (timelogStore &&
                timelogStore.currentList &&
                timelogStore.currentList.rows &&
                timelogStore.currentList.rows.filter &&
                timelogStore.currentList.rows.filter((row) => row.id === params.ids[0])[0]) ||
            {};
        if (params.entity === 'timelog' && shiftAllowance) {
            if (!firstItem.data) firstItem.data = {};
            if (!firstItem.data?.extraPayments) firstItem.data.extraPayments = {};
            let data = {
                ...firstItem.data.extraPayments, 'shift_allowance': {
                    value: shiftAllowance.amount, extraPaymentType: {
                        value: "fixed_hourly_amount",
                        label: "Fixed amount for shift"
                    },
                }
            }
            if (data) firstItem.data.extraPayments = data;
        }
        if (
            (params.count === 1 || params.count === '1') &&
            firstItem.validateTimetrackOption &&
            params.startedByWorklogButton
        ) {

            const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';
            if (clientInfo) {
                return (
                    <div className="mb-5">
                        <div className="primary-page__title mt-2">{t('Registered working hours')}</div>
                        <div className="row timelog-grid-time-card mt-2">
                            <Col xs={12} md={6}
                                className="timelog-grid-item-1 justify-content-space-between card-timelog-grid-item"
                            >
                                <div className="d-flex">
                                    <i className="timelog-grid__icon"></i>
                                    <span className="timelog-grid__text">{t('Start')}</span>
                                </div>
                                <b className="timelog-final-time-card-modal">
                                    {timeFormat(firstItem.from, dateTimeRules)}
                                </b>
                            </Col>
                            <Col xs={12} md={6}
                                className="timelog-grid-item-2 justify-content-space-between card-timelog-grid-item"
                            >
                                <div className="d-flex align-items-center">
                                    <i className="timelog-grid__icon"></i>
                                    <span className="timelog-grid__text">{t('End')}</span>
                                </div>
                                <b className="timelog-final-time-card-modal">
                                    {timeFormat(firstItem.to, dateTimeRules)}
                                </b>
                            </Col>
                        </div>
                        <div className="row timelog-grid-time-card">
                            <Col xs={12} md={6}
                                className="timelog-grid-item-1 justify-content-space-between card-timelog-grid-item"
                            >
                                <div className="d-flex align-items-center">
                                    <span className="timelog-grid__text ml-30">{t('Breaktime')}</span>
                                </div>
                                <b className="timelog-final-time-card-modal">
                                    {minutesToTimeInput(firstItem.break || 0)}
                                </b>
                            </Col>
                            <Col xs={12} md={6}
                                className="timelog-grid-item-2 justify-content-space-between card-timelog-grid-item"
                            >
                                <div className="d-flex align-items-center">
                                    <span className="timelog-grid__text ml-30">{t('Total')}</span>
                                </div>
                                <b className="timelog-final-time-card-modal">
                                    {firstItem.from > firstItem.to ?
                                        minutesToTimeInput(1440 - firstItem.from + firstItem.to - (firstItem.break || 0)) :
                                        minutesToTimeInput(firstItem.to - firstItem.from - (firstItem.break || 0))
                                    }
                                </b>
                            </Col>
                        </div>
                        {!firstItem.data.toChangedById && (firstItem.anomaly?.start?.is_early || firstItem.anomaly?.start?.is_late || firstItem.anomaly?.start?.is_offsite || firstItem.anomaly?.end?.is_early || firstItem.anomaly?.end?.is_late || firstItem.anomaly?.end?.is_offsite) ?
                            <div className='row gx-0 justify-content-center mt-2 fw-bold'>
                                {firstItem.anomaly?.start?.is_early || firstItem.anomaly?.start?.is_late || firstItem.anomaly?.start?.is_offsite ?
                                    <>
                                        <Row className='w-100'>
                                            <Col className='d-flex justify-content-around' xs={12} md={6}>
                                                <div>{t('Clock In')}:&nbsp;</div>
                                                <div className={`d-flex align-items-center ${firstItem.anomaly?.start?.is_late ? 'text-danger' : firstItem.anomaly?.start?.is_early ? "text-warning " : "text-success"}`}>
                                                    <i className='fa fa-clock-o' />
                                                    {firstItem.anomaly?.start?.is_late ? t('Late') : firstItem.anomaly?.start?.is_early ? t('Early') : t('On-time')}
                                                </div>
                                            </Col>
                                            <Col className='d-flex justify-content-around' xs={12} md={6}>
                                                <div>{t('Location')}:&nbsp;</div>
                                                <div className={`d-flex align-items-center ${firstItem.anomaly?.start?.is_offsite ? "text-danger d-flex align-items-center" : "text-success"}`}>
                                                    <i className='fa fa-map-marker' />
                                                    {firstItem.anomaly?.start?.is_offsite ? t('Off-site') : t('On-site')}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='w-100 mt-2 d-flex mb-3'>
                                            <div className='d-flex justify-content-start border-bottom'>
                                                <div className='w-25 d-md-flex justify-content-md-center mb-2'>{`${t('Comment')}`}:&nbsp;</div>
                                                <div className='w-75 text-info'>{t(firstItem.anomaly?.start?.comment)}</div>
                                            </div>
                                        </Row>
                                    </>
                                    : null
                                }
                                {firstItem.anomaly?.end?.is_early || firstItem.anomaly?.end?.is_late || firstItem.anomaly?.end?.is_offsite ?
                                    <>
                                        <Row className='w-100'>
                                            <Col className='d-flex justify-content-around' xs={12} md={6}>
                                                <div>{t('Clock Out')}:&nbsp;</div>
                                                <div className={firstItem.anomaly?.end?.is_late ? "text-danger" : firstItem.anomaly?.end?.is_early ? "text-warning" : "text-success"}>
                                                    <i className='fa fa-clock-o' />
                                                    {firstItem.anomaly?.end?.is_late ? t('Late') : firstItem.anomaly?.end?.is_early ? t('Early') : t('On-time')}
                                                </div>
                                            </Col>
                                            <Col className='d-flex justify-content-around' xs={12} md={6}>
                                                <div>{t('Location')}:&nbsp;</div>
                                                <div className={firstItem.anomaly?.end?.is_offsite ? "text-danger" : "text-success"}>
                                                    <i className='fa fa-map-marker' />
                                                    {firstItem.anomaly?.end?.is_offsite ? t('Off-site') : t('On-site')}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='w-100 mt-2 mb-2'>
                                            <Col xs={12} md={12}>
                                                <div className='d-flex justify-content-start'>
                                                    <div className='w-25 d-md-flex justify-content-md-center'>{`${t('Comment')}`}:&nbsp;</div>
                                                    <div className='w-75 text-info'>{t(firstItem.anomaly?.end?.comment)}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                    : null
                                }
                            </div>
                            : null
                        }
                        <div className='table-list__header mb-2 gap-2'>
                            <GPSInfo
                                status={firstItem.status}
                                data={firstItem.gps_data}
                                full={firstItem}
                                mode="modal"
                            />
                            <OverlayTrigger
                                key="timelog_edit"
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        {t(firstItem.is_editable ?
                                            firstItem.status === 'approved' ?
                                                'Hours are already approved'
                                                : 'Adjust Timesheet'
                                            : 'Cannot manage this timesheet')}
                                    </Tooltip>
                                }
                            >
                                <Button fill wd
                                    onClick={() =>
                                        this.setState((state) => ({
                                            showTimeEditAndComment: !state.showTimeEditAndComment,
                                        }))
                                    }
                                    disabled={(firstItem.status === 'approved' || !firstItem.is_editable)}
                                >
                                    {t('Adjust')}
                                </Button>
                            </OverlayTrigger>


                        </div>
                        {this.state.showTimeEditAndComment && (
                            <div>
                                <br />
                                <div className="row timelog-grid-time-card">
                                    <Col xs={12} md={6}
                                        className="timelog-grid-item-1 justify-content-space-between card-timelog-grid-item height-50"
                                    >
                                        <div className="d-flex align-items-center">
                                            <i className="timelog-grid__icon"></i>
                                            <span className="timelog-grid__text">{t('Start')}</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <TimePickerInput
                                                value={this.state.fromInput}
                                                format={format === 'hh:mm' ? 'HH:mm' : format}
                                                onChange={(value) => this.setState({ fromInput: value })}
                                                className="worklog-input border-radius-10 borderworklog"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6}
                                        className="timelog-grid-item-2 justify-content-space-between card-timelog-grid-item height-50"
                                    >
                                        <div className="d-flex align-items-center">
                                            <i className="timelog-grid__icon"></i>
                                            <span className="timelog-grid__text">{t('End')}</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <TimePickerInput
                                                style={{ width: 150 }}
                                                value={this.state.toInput}
                                                format={format === 'hh:mm' ? 'HH:mm' : format}
                                                onChange={(value) => this.setState({ toInput: value })}
                                                className="worklog-input border-radius-10 borderworklog"
                                            />
                                        </div>
                                    </Col>
                                </div>
                                <div className="row timelog-grid-time-card">
                                    <Col xs={12} md={6}
                                        className="timelog-grid-item-1 justify-content-space-between card-timelog-grid-item height-50"
                                    >
                                        <div className="d-flex align-items-center">
                                            <span className="timelog-grid__text ml-30">{t('Breaktime')}</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <InputTime
                                                value={this.state.breakInput}
                                                onChange={(e) => {
                                                    this.setState({
                                                        breakInput: e,
                                                    });
                                                }}
                                                className="big-input text-center timelog-final-time-card-total"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6}
                                        className="timelog-grid-item-2 justify-content-space-between card-timelog-grid-item height-50"
                                    >
                                        <div className="d-flex align-items-center">
                                            <span className="timelog-grid__text ml-30">{t('Total')}</span>
                                        </div>
                                        <b className="timelog-final-time-card-modal">
                                            {this.state.fromInput > this.state.toInput ?
                                                minutesToTimeInput(
                                                    1440 - getMinutesFromTimeInput(this.state.fromInput)
                                                    + getMinutesFromTimeInput(this.state.toInput)
                                                    - getMinutesFromTimeInput(this.state.breakInput))
                                                :
                                                minutesToTimeInput(
                                                    getMinutesFromTimeInput(this.state.toInput)
                                                    - getMinutesFromTimeInput(this.state.fromInput)
                                                    - getMinutesFromTimeInput(this.state.breakInput))
                                            }
                                        </b>
                                    </Col>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button fill dynamic_lg
                                        onClick={() => {
                                            this.setState((state) => {
                                                const { clientInfo } = state;
                                                const fromInput =
                                                    (clientInfo &&
                                                        clientInfo.client &&
                                                        clientInfo.client.data &&
                                                        clientInfo.client.data.basicRules &&
                                                        clientInfo.client.data.basicRules &&
                                                        clientInfo.client.data.basicRules.startTimeRules &&
                                                        clientInfo.client.data.basicRules.startTimeRules.start) ||
                                                    state.fromInput;
                                                const toInput =
                                                    (clientInfo &&
                                                        clientInfo.client &&
                                                        clientInfo.client.data &&
                                                        clientInfo.client.data.basicRules &&
                                                        clientInfo.client.data.basicRules &&
                                                        clientInfo.client.data.basicRules.startTimeRules &&
                                                        clientInfo.client.data.basicRules.startTimeRules.end) ||
                                                    state.toInput;
                                                let breakMinutes;
                                                if (
                                                    clientInfo &&
                                                    clientInfo.client &&
                                                    clientInfo.client.data &&
                                                    clientInfo.client.data.basicRules &&
                                                    clientInfo.client.data.basicRules &&
                                                    clientInfo.client.data.basicRules.breakTimeRules &&
                                                    clientInfo.client.data.basicRules.breakTimeRules.minutes
                                                ) {
                                                    breakMinutes =
                                                        +clientInfo.client.data.basicRules.breakTimeRules.minutes;
                                                }
                                                if (!breakMinutes && breakMinutes !== 0) {
                                                    breakMinutes = getMinutesFromTimeInput(state.breakInput);
                                                }
                                                const breakInput = minutesToTimeInput(breakMinutes);
                                                return {
                                                    fromInput,
                                                    toInput,
                                                    breakInput,
                                                };
                                            });
                                        }}
                                    >
                                        {t('Standard company time')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        <Row>
                            <Col sm={3} className='mt-4'>
                                <ul className="list-group">
                                    <li className={currentTab === 'shifts' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                        onClick={() => this.setState({ currentTab: 'shifts' })}>
                                        <div>
                                            {t('Shifts')}
                                            <Badge className='ms-2 bg-secondary float-end'>{this.state?.shiftDetails?.length}</Badge>
                                        </div>
                                    </li>
                                    <li className={currentTab === 'overtime' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                        onClick={() => this.setState({ currentTab: 'overtime' })}>
                                        <div>
                                            {t('Overtime Hours')}
                                            <Badge className='ms-2 bg-secondary float-end'>{overtimes ? Object.values(overtimes).length : 0}</Badge>
                                        </div>
                                    </li>
                                    <li className={currentTab === 'addtionals' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                        onClick={() => this.setState({ currentTab: 'addtionals' })}>
                                        <div>
                                            {t('Additional Hours')}
                                            <Badge className='ms-2 bg-secondary float-end'>{firstItem.data?.extraPayments ? Object.values(firstItem.data?.extraPayments).length : 0}</Badge>
                                        </div>
                                    </li>
                                </ul>
                            </Col>
                            <Col sm={9}>
                                <Tab.Container activeKey={currentTab} id='modal-accept'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="shifts">
                                            <h5 className='fw-600'>{t('Shifts')}</h5>
                                            {this.state.shiftDetails?.length ?
                                                <div className='timelog-array__right w-100'>
                                                    <Table className='w-100 '>
                                                        <thead>
                                                            <tr className='w-100'>
                                                                <th width={'25%'}>{t('Project')}</th>
                                                                <th width={'25%'}>{t('Task')}</th>
                                                                <th width={'25%'}>{t('Date')}</th>
                                                                <th width={'25%'}>{t('Time')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.shiftDetails.map((ele, idx) => (
                                                                <tr key={idx} className='text-capitalize mt-1'>
                                                                    <td width={'25%'} className=''><div className='d-flex align-items-center justify-content-center min-height-20 ps-2'>{ele.projectName}</div></td>
                                                                    <td width={'25%'} className='timelog_array_details'>{ele.taskName}</td>
                                                                    <td width={'25%'} className='timelog_array_details'>{shortDateFormat(ele.forDate, dateTimeRules)}</td>
                                                                    <td width={'25%'} className='timelog_array_details'>{ele.title}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                :
                                                <div className='text-center'>
                                                    {t('No Shifts Found for the day')}!
                                                </div>
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="overtime">
                                            <h5 className='fw-600'>{t('Overtimes Hours')}</h5>
                                            {overtimes && Object.keys(overtimes)?.length ?
                                                <>
                                                    <div className="row timelog-grid-time-card">
                                                        {overtimeArray && overtimeArray.length &&
                                                            overtimeArray.map((overtime, index) => (
                                                                overtimes[overtime?.code]?.value ?
                                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className='timelog-grid-extra-payment-item_approval justify-content-space-between card-timelog-grid-item' key={`${index}_${overtime.code}`}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <span className="timelog-grid__text timelog-grid__text-capitalize w-100">
                                                                                {overtime.name}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <InputTime
                                                                                value={
                                                                                    overtimes[overtime.code]
                                                                                        ? overtimes[overtime.code].value
                                                                                        : '00:00'
                                                                                }
                                                                                disabled={true}
                                                                                className="big-input text-center"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    : null
                                                            ))}
                                                    </div>
                                                </>
                                                :
                                                <div className='text-center'>
                                                    {t('No Overtime Record Found for the day')}!
                                                </div>}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="addtionals">
                                            <h5 className='fw-600'>{t('Additional Hours')}</h5>
                                            {firstItem.data?.extraPayments && Object.values(firstItem.data?.extraPayments).length ? (
                                                <>
                                                    {commonStore.config?.client?.data?.extraPayments?.length &&
                                                        <div className='font-size-15 fw-semibold' style={{ color: '#446bbb' }}>
                                                            {t('Time&Control suggests that you double-check all the additional payments before approving and sending them to payroll to avoid any mistakes')}.
                                                        </div>
                                                    }
                                                    <div className="row timelog-grid-time-card">
                                                        {commonStore.config.client.data?.extraPayments.length &&
                                                            commonStore.config.client.data.extraPayments.map((extraPayment, index) =>
                                                                firstItem.data?.extraPayments[extraPayment.code]?.value ? (
                                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className='timelog-grid-extra-payment-item_approval justify-content-space-between card-timelog-grid-item ' key={`${index}_${extraPayment.code}`}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <span className="timelog-grid__text timelog-grid__text-capitalize w-100">
                                                                                {extraPayment.name}
                                                                            </span>
                                                                        </div>
                                                                        <OverlayTrigger
                                                                            key={extraPayment?.name}
                                                                            placement="top"
                                                                            overlay={<Tooltip id="tooltip-top">{t('Add Extra Payments Hours')}</Tooltip>}
                                                                        >
                                                                            <div>
                                                                                {extraPayment?.extraPaymentType?.value === 'fixed_hourly_amount' || extraPayment.code === 'shift_allowance' ?
                                                                                    <NumberInput
                                                                                        style={{ maxWidth: '85px' }}
                                                                                        customClassses="text-center"
                                                                                        disabled={!extraPayment.isEditable || shiftAllowance?.amount}
                                                                                        value={firstItem.data?.extraPayments[extraPayment.code]?.value
                                                                                            ? firstItem.data?.extraPayments[extraPayment.code]?.value
                                                                                            : 0}
                                                                                    />
                                                                                    :
                                                                                    <InputTime
                                                                                        value={
                                                                                            firstItem.data?.extraPayments[extraPayment.code]?.value
                                                                                                ? minutesToTimeInput(firstItem.data?.extraPayments[extraPayment.code]?.value)
                                                                                                : '00:00'
                                                                                        }
                                                                                        className="big-input text-center"
                                                                                        onChange={(e) => this.handleChangeExtraPayments(extraPayment, e)}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    </Col>
                                                                ) :
                                                                    <></>
                                                            )}
                                                        {commonStore.config.client.data?.extendedRules.kilometersRules?.length &&
                                                            commonStore.config.client.data.extendedRules.kilometersRules.map((extraPayment, index) =>
                                                                !!firstItem.data?.extraPayments[extraPayment.distance.replaceAll(" ", "").toLocaleLowerCase()]?.value && (
                                                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className='timelog-grid-extra-payment-item_approval justify-content-space-between card-timelog-grid-item ' key={`${index}_${extraPayment.code}`}>
                                                                        <div className='d-flex align-items-center'>
                                                                            <span className="timelog-grid__text timelog-grid__text-capitalize w-100">
                                                                                {t('Km/Miles reimbursement')} ({extraPayment.distance})
                                                                            </span>
                                                                        </div>
                                                                        <OverlayTrigger
                                                                            key={extraPayment?.distance}
                                                                            placement="top"
                                                                            overlay={<Tooltip id="tooltip-top">{t('These will be automatically filled by the system')}</Tooltip>}
                                                                        >
                                                                            <div>
                                                                                <NumberInput
                                                                                    style={{ maxWidth: '85px' }}
                                                                                    customClassses="text-center"
                                                                                    disabled={!extraPayment.isEditable}
                                                                                    value={firstItem.data?.extraPayments[extraPayment.distance.replaceAll(" ", "").toLocaleLowerCase()]?.value
                                                                                        ? firstItem.data?.extraPayments[extraPayment.distance.replaceAll(" ", "").toLocaleLowerCase()]?.value
                                                                                        : 0}
                                                                                />
                                                                            </div>
                                                                        </OverlayTrigger>
                                                                    </Col>
                                                                )
                                                            )}
                                                    </div>
                                                </>
                                            ) : <div className='text-center'>
                                                {t('No Additional Hours Registered')}!
                                            </div>
                                            }
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Col>
                        </Row >
                        <Row className="mt-20">
                            <Col>
                                <Textarea
                                    label={`${t('Add')} ${t('Notes')}`}
                                    value={this.props.admin_note ? this.props.admin_note : this.state.admin_note}
                                    onChange={e => this.setState({ admin_note: e.target.value })}
                                    placeholder={t('Enter Notes here')}
                                    required
                                    timelog_modal={"_timelog"}
                                />
                            </Col>
                            <Col>
                                <Textarea
                                    label={t('Comment')}
                                    value={this.props.notes ? this.props.notes : this.state.notes}
                                    onChange={(e) => this.setState({ notes: e.target.value })}
                                    placeholder={t('Enter comments here')}
                                    required
                                    timelog_modal={"_timelog"}
                                />
                            </Col>
                        </Row>
                        {this.props.commonStore.config?.integration_details?.product ?
                            <div className='mt-2 d-flex justify-content-start' title={this.state.validationMessage === '' ? null : this.state.validationMessage}>
                                <div className='mt-1'>
                                    <SwitchWidget
                                        value={this.state.syncWithIntegration}
                                        onChange={checked => this.handleSyncSwitch(checked)}
                                        disabled={this.state.validationMessage === '' && !this.state.isAlreadySynced ? false : true}
                                    />
                                </div>
                                <div>
                                    <label className='ml-10'>{t(`Do You want to Save the hours to`)} {this.props.commonStore.config?.integration_details?.product}?</label>
                                </div>
                            </div>
                            :
                            <></>
                        }
                        {(!!fromChangedBy || !!toChangedBy || !!breakChangedBy) &&
                            <div className="row mt-2">
                                <Col xs={12} className="d-flex justify-content-start">
                                    <p className='font-size-15 fw-bold mt-2'>{t("Change History")}</p>
                                </Col>
                                <Col xs={12}>
                                    {fromChangedBy && (previousFrom || previousFrom === 0) && (
                                        <p style={{ margin: 0 }}>
                                            {t(this.convertUserTypeToText(fromChangedBy.user_type))}{' '}
                                            {fromChangedBy.first_name || ''} {fromChangedBy.last_name || ''}{' '}
                                            {t('changed start time from')} {timeFormat(previousFrom, dateTimeRules)}{' '}
                                            {t('to')} {timeFormat(firstItem.from, dateTimeRules)}
                                        </p>
                                    )}
                                    {toChangedBy && (previousTo || previousTo === 0) && (
                                        <p style={{ margin: 0 }}>
                                            {t(this.convertUserTypeToText(toChangedBy.user_type))}{' '}
                                            {toChangedBy.first_name || ''} {toChangedBy.last_name || ''}{' '}
                                            {t('changed end time from')} {timeFormat(previousTo, dateTimeRules)} {t('to')}{' '}
                                            {timeFormat(firstItem.to || 0, dateTimeRules)}
                                        </p>
                                    )}
                                    {breakChangedBy && (previousBreak || previousBreak === 0) && (
                                        <p style={{ margin: 0 }}>
                                            {t(this.convertUserTypeToText(breakChangedBy.user_type))}{' '}
                                            {breakChangedBy.first_name || ''} {breakChangedBy.last_name || ''}{' '}
                                            {t('changed break time from')} {minutesToTimeInput(previousBreak)} {t('to')}{' '}
                                            {minutesToTimeInput(firstItem.break)}
                                        </p>
                                    )}
                                </Col>
                            </div>
                        }
                        {this.state.approvalTimelogsIsManual ? (
                            <div className='timelog-right-fade-modal-container'>
                                <div className="timelog-modal-accept-buttons">
                                    <Button fill wd icon
                                        style={{ backgroundColor: 'blue', border: 0, opacity: this.isDataModified() ? "1" : "0.5" }}
                                        onClick={() => this.saveOneAndAccept()}
                                        disabled={!firstItem.is_editable || (firstItem.status === 'approved' && !this.isDataModified())}
                                        title={firstItem.status === 'approved' ? 'Hours are already approved' : ''}
                                    >
                                        <img src={SaveAndAcceptIcon} alt="SaveAndAcceptIcon" /> {t('Save and accept')}
                                    </Button>
                                    <Button fill wd icon style={{ backgroundColor: 'green', border: 0, alignItems: 'center' }}
                                        onClick={() => this.handleRun('approved')}
                                        disabled={firstItem.status === 'approved'}
                                        title={firstItem.status === 'approved' ? 'Hours are already approved' : ''}
                                    >
                                        <img src={ApprovalIcon} alt="ApprovalIcon" /> {t('Accept original')}
                                    </Button>
                                    <Button fill wd icon style={{ backgroundColor: 'red', border: 0 }}
                                        onClick={() => this.handleRun('rejected')}
                                    >
                                        <img src={RejectIcon} alt="RejectIcon" /> {t('Reject')}
                                    </Button>
                                    <Button fill wd icon
                                        style={{ backgroundColor: 'darkorange', border: 0, opacity: this.isStatusUpdated() ? "1" : "0.5" }}
                                        onClick={() => this.handleRun('reset')}
                                        disabled={!this.isStatusUpdated()}
                                    >
                                        <img src={ResetApprovalIcon} alt="ResetApprovalIcon" /> {t('Reset approval')}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className='text-center'>
                                <Button fill wd icon style={{ backgroundColor: 'blue', border: 0 }}
                                    onClick={() => this.saveOne()}
                                >
                                    <img src={ApprovalIcon} alt="ApprovalIcon" /> {t('Save')}
                                </Button>
                            </div>
                        )}
                    </div>
                );
            }
        }

        return (
            <div>
                <h3 className="modalAcceptCount">
                    {t('Selected items')} <b>({params.count})</b>
                </h3>
                {!!(this.state.shiftDetails?.length && (params.entity === 'vacation' || params.entity === 'absence')) &&
                    <div className='timelog-array__right w-100'>
                        <div> {`${t("This")} ${params.entity} ${t("has intersecting shift(s) as listed below")}`}</div>
                        <Accordion allowZeroExpanded preExpanded={['shifts']} className="timelog_modal">
                            <AccordionItem title={`${t('Scheduled')} ${t('shifts')}`} uuid='shifts'>
                                <Table className='w-100 '>
                                    <thead>
                                        <tr className='w-100'>
                                            <th width={'25%'}>{t('Project')}</th>
                                            <th width={'25%'}>{t('Task')}</th>
                                            <th width={'25%'}>{t('Date')}</th>
                                            <th width={'25%'}>{t('Time')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.shiftDetails?.map((ele, idx) => (
                                            <tr key={idx} className='text-capitalize mt-1'>
                                                <td width={'25%'} className=''><div className='d-flex align-items-center min-height-20 ps-2'>{ele.projectName}</div></td>
                                                <td width={'25%'} className='timelog_array_details'>{ele.taskName}</td>
                                                <td width={'25%'} className='timelog_array_details'>{shortDateFormat(ele?.forDate, dateTimeRules)}</td>
                                                <td width={'25%'} className='timelog_array_details'>{ele.title}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </AccordionItem>
                        </Accordion>
                        <div className='row'>
                            <p>{t("Please select what would you like to do with with these shifts")}?</p>
                            <div className='col-3'>
                                <CustomRadio
                                    label={t('Move shifts to open')}
                                    checked={moveShiftsToOpen}
                                    onChange={(evt) => this.setState({ moveShiftsToOpen: evt.target.checked ? true : false })}
                                />
                            </div>
                            <div className='col-6'>
                                <CustomRadio
                                    label={(this.isEmergencyShiftEnabled || this.isEmergencyShiftEnabled) ? `${t('Remove shifts')}(${t('Also shift allowances')})` : t('Remove shifts')}
                                    checked={!moveShiftsToOpen}
                                    onChange={(evt) => this.setState({ moveShiftsToOpen: evt.target.checked ? false : true })}
                                />
                            </div>
                            {(this.isEmergencyShiftEnabled || this.isEmergencyShiftEnabled) &&
                                <p className='font-size-12 color-FF8383'><strong>{t('Note')}:&nbsp;</strong>
                                    {t('Shift allowances and Emergency shifts(On-call) will be removed automatically if found')}.
                                </p>
                            }
                        </div>
                    </div>
                }

                <Textarea
                    value={this.state.notes}
                    onChange={(e) => this.setState({ notes: e.target.value })}
                    placeholder={t('Comment')}
                    required
                />

                {this.props.commonStore.config?.integration_details?.product ?
                    <div className='mt-2 d-flex justify-content-start' title={this.state.validationMessage === '' ? null : this.state.validationMessage}>
                        <div className='mt-1'>
                            <SwitchWidget
                                value={this.state.syncWithIntegration}
                                onChange={checked => this.handleSyncSwitch(checked)}
                                disabled={this.state.validationMessage === '' ? false : true}
                            />
                        </div>
                        <label className='ml-10'>{`${t(`Do You want to Save the hours to`)} ${this.props.commonStore.config?.integration_details?.product}`}</label>
                    </div>
                    :
                    <></>
                }
                <div className="timelog-modal-accept-buttons mt-20">
                    <Button fill dynamic_lg style={{ backgroundColor: 'green', border: 0 }}
                        onClick={() => this.handleRun('approved')}
                    >
                        <img src={ApprovalIcon} alt="ApprovalIcon" />
                        {t('Accept')}
                    </Button>
                    <Button fill dynamic_lg style={{ backgroundColor: 'red', border: 0 }}
                        onClick={() => this.handleRun('rejected')}
                    >
                        <img src={RejectIcon} alt="RejectIcon" />
                        {t('Reject')}
                    </Button>
                    <Button fill dynamic_lg
                        style={{ backgroundColor: 'darkorange', border: 0, opacity: this.isStatusUpdated() ? "1" : "0.5", color: "white" }}
                        onClick={() => this.handleRun('reset')}
                        disabled={this.isStatusUpdated() ? false : true}
                    >
                        <img src={ResetApprovalIcon} alt="ResetApprovalIcons" /> {t('Reset approval')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default inject('timelogStore', 'absenceStore', 'commonStore', 'userStore', 'scheduleStore', 'vacationStore'
)(applicationRouter(withLocalization(observer(ModalAccept))));