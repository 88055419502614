import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { confirmAlert } from 'react-confirm-alert';
import { Row, Col } from 'react-bootstrap';

//component
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '../../../hoc/withLocalization';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Textarea } from '../../../elements/Input';

//utils
import { statusMap } from '~/utils/normalStatuses';

class AcceptFormBlock extends Component {
    state = {
        notes: '',
        status: ''
    };

    componentDidMount() {
        if (this.props && this.props.params.data &&
            this.props.params.data.absence &&
            this.props.params.data.absence.status_note) {
            this.setState({
                notes: this.props.params.data.absence.status_note,
                status: this.props.params.data.absence.status
            });
        }
        if (this.props && this.props.params.data &&
            this.props.params.data.vacation &&
            this.props.params.data.vacation.status_note) {
            this.setState({
                notes: this.props.params.data.vacation.status_note,
                status: this.props.params.data.vacation.status
            });
        }
    }

    getaction(action) {
        if (action === 'rejected') {
            return 'reject this';
        }
        else if (action === 'approved') {
            return 'approve this'
        }
        else if (action === 'reset') {
            return 'reset this'
        }
        else {
            return 'perform this Action';
        }
    }
    async handleChangeStatus(action) {
        const { commonStore, params } = this.props;
        if (action === 'rejected' && !this.state.notes)
            return commonStore.addNotification(this.props.t('Comment is required'), null, 'error');

        if (this.state.status === 'approved' || this.state.status === 'rejected' || action === 'reset') {
            const str = this.props.t(`Are you sure to ` + this.getaction(action) + `?`);
            return confirmAlert({
                message: str,
                buttons: [
                    {
                        label: this.props.t('Yes'),
                        onClick: () => {
                            this.handleStatusUpdate(params.id, params.entity, action, { notes: this.state.notes })
                        },
                    },
                    {
                        label: this.props.t('No'),
                        onClick: () => { },
                    },
                ],
            });
        }
        else
            this.handleStatusUpdate(params.id, params.entity, action, { notes: this.state.notes })
    }

    handleStatusUpdate = async (id, entity, action, notes) => {
        const { timelogStore, afterSave, commonStore } = this.props;
        try {
            await timelogStore.setStatus(id, entity, action, notes);
            commonStore.addNotification(this.props.t('Success'), null, 'success');
            if (afterSave) afterSave();
        }
        catch (err) {
            commonStore.addNotification(this.props.t('Something went wrong'), null, 'error');
        }
    }



    render() {
        const { t, params } = this.props;
        if (!params) return null;
        let { status } = params;
        if (!status || status === 'null') status = 'active';
        let statusText = statusMap[status] || '';
        let entityName = null;
        if (params.entity === 'absence') entityName = 'Absents approval';
        if (params.entity === 'vacation') entityName = 'Vacations approval';
        if (params.entity === 'timelog') entityName = 'Time log approval';
        const isApproved = status === "approved";
        return (
            <>
                <Row className='mt-4 mb-2 align-items-center'>
                    <Col xs={8} sm={10} md={10} lg={10} xl={11}>
                        <span className='table-container-header mb-3'>{t(entityName)}</span>
                    </Col>
                    <Col xs={4} sm={2} md={2} lg={2} xl={1}>
                        <div className={`status-block status-${status}`}>
                            {t(statusText)}
                        </div>
                    </Col>
                </Row>
                {!isApproved ?
                    <Row>
                        <Textarea
                            label={t('Comment')}
                            value={this.state.notes}
                            onChange={e => this.setState({ notes: e.target.value })}
                            placeholder={t('Enter comments here')}
                            required
                        />
                    </Row> : <></>}
                <Row className='mt-4 align-items-center'>
                    {!isApproved ?
                        <>
                            <Col xs={6} sm={3} md={2}
                                className={"mt-2 mt-sm-0 mt-md-0 text-center text-sm-start"}>
                                <Button
                                    fill
                                    wd
                                    type="submit"
                                    style={{ backgroundColor: 'green', border: 0 }}
                                    onClick={() => this.handleChangeStatus('approved')}
                                >
                                    <i className="fa fa-check" /> {t('Accept')}
                                </Button>
                            </Col>
                            <Col xs={6} sm={3} md={2} className={"mt-2 mt-sm-0 mt-md-0 text-center text-sm-start"}>
                                <Button
                                    fill
                                    wd
                                    type="submit"
                                    style={{ backgroundColor: 'red', border: 0 }}
                                    onClick={() => this.handleChangeStatus('rejected')}
                                >
                                    <i className="fa fa-times" /> {t('Reject')}
                                </Button>
                            </Col>
                        </>
                        : <></>}
                    <Col xs={12} sm={{ span: 4, offset: isApproved ? 8 : 2 }}
                        md={{ span: 3, offset: isApproved ? 9 : 5 }}
                        className={`mt-2 mt-sm-0 mt-md-0 ${isApproved ? 'text-end' : 'text-center'} text-sm-end`}>
                        <Button
                            fill
                            wd
                            type="submit"
                            style={{ backgroundColor: 'gray', border: 0 }}
                            onClick={() => this.handleChangeStatus('reset')}
                        >
                            <i className="pe-7s-refresh" /> {t('Reset approval')}
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}

export default inject('timelogStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(AcceptFormBlock))));
