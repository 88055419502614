import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';

// Components
import applicationRouter from '~/hoc/applicationRouter';
import GenericList from '~/components/GenericList/GenericList';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import ChecklistTemplateAddEdit from './ChecklistTemplateAddEdit';
import GenericFilter from '../../../../components/GenericList/GenericFilter';

//elements
import Button from '../../../../components/CustomButton/CustomButton.jsx';

//utils
import { shortDateFormat } from '~/utils/dateFormat';
import customerFeature from '~/utils/customerFeature';

//assets
import editIcon from '../../../../assets/img/editing.svg';
import deleteIcon from '../../../../assets/img/deleting.svg';
// import CloseModalIcon from '../../../../assets/img/CloseApproveModalIcon.svg'

//styles
import '../../../../styles/css/styles.css';
class ChecklistTemplateList extends Component {
    state = {
        checklistTemplateArray: [],
        showChecklistTemplateAddEdit: false,
        checklistdata: null,
        id: 0,
    };

    handleTableButtonAction = (id, type) => {
        if (type === 'edit') {
            this.viewChecklistTemplatesEdit(id);
        }
        if (type === 'remove') {
            this.removeChecklist(id);
        }
    };

    removeChecklist(id) {
        return this.props.handleDelete(id, (id) => {
            return this.props.checklistStore.checklistTemplateDelete(id).then((res) => {
                this.props.checklistStore.resetLastListLoadTimeCheckTemplate();
                return res;
            });
        });
    }

    renderTableActionButtons = (id, full) => {
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');

        return (
            <div className="actions-center">
                <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'edit')}>
                    <img src={editIcon} alt={'edit button'} />
                </Button>
                {(user_type === 'member' || (user_type === 'member' && full.assignee_id === userId) || allow) &&
                    !full.is_default_for_project && (
                        <Button icon_sm_delete fill onClick={() => this.handleTableButtonAction(id, 'remove')}>
                            <img src={deleteIcon} alt="delete button" />
                        </Button>
                    )}
            </div>
        );
    };

    componentWillMount() {
        const params = new URLSearchParams(this.props.router.location.search);
        const filterByProject = params.get('project');
        this.props.checklistStore.setFilter('project', filterByProject || null);
        this.props.checklistStore.setFilter('name', '');
        this.props.checklistStore.onFilter();
    }

    handleClosesChecklistTemplateAddEditModal = () => {
        this.setState({ showChecklistTemplateAddEdit: false, id: 0 });
        this.props.checklistStore.returnDefaultNewChecklistTemplate();
    };

    viewChecklistTemplatesAdd = () => {
        this.setState({
            add: true,
            showChecklistTemplateAddEdit: true,
        });
    };

    viewChecklistTemplatesEdit = (id) => {
        this.setState({
            add: false,
            id: id,
            showChecklistTemplateAddEdit: true,
        });
    };

    savechecklist = async () => {
        const { t, checklistStore, commonStore } = this.props;
        let checklistTemplateData = checklistStore.checklistTemplateData;
        if (
            checklistTemplateData == null ||
            checklistTemplateData.name === undefined ||
            checklistTemplateData.name === '' ||
            checklistTemplateData.name == null
        ) {
            commonStore.addNotification(t('Please Enter checklist name.'), null, 'error');
            return;
        } else if (checklistTemplateData.project_id === undefined || checklistTemplateData.project_id === '') {
            commonStore.addNotification(t('Please Select Project.'), null, 'error');
            return;
        } else if (
            checklistTemplateData.element_data === undefined ||
            (checklistTemplateData.element_data && checklistTemplateData.element_data.length === 0)
        ) {
            commonStore.addNotification(t('Please add Elements.'), null, 'error');
            return;
        }

        await checklistStore
            .addChecklistTempalteNew()
            .then((data) => {
                if (data) {
                    this.props.commonStore.addNotification(t('Checklist template Saved'), null, 'success');
                    this.props.checklistStore.resetLastListLoadTimeCheckTemplate();
                    this.handleClosesChecklistTemplateAddEditModal();
                }
            })
            .catch((err) => {
                commonStore.addNotification(err.message || t('Error'), null, 'error');
            });
    };

    render() {
        const { t } = this.props;
        const { loading, filters, appliedFilters } = this.props.checklistStore;
        const { config } = this.props.commonStore;
        const dateTimeRules =
            config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;
        return (
            <div className="table-list">
                <div className="table-list__header">
                    <Button fill wd onClick={() => this.viewChecklistTemplatesAdd()}>
                        <i className="fa fa-plus-circle fa-xl margin-right-10" />
                        {this.props.t('Add')}
                    </Button>
                </div>
                <GenericList
                    columns={[
                        {
                            Header: 'Name',
                            id: 'name',
                            accessor: ({ id, name }) => (
                                <div
                                    className="list-main-column"
                                    onClick={() => this.handleTableButtonAction(id, 'edit')}
                                >
                                    {`${name}`}
                                </div>
                            ),
                            isSortable: true,
                            minWidth: 250,
                            textAlign: 'start'
                        },
                        {
                            Header: 'Project',
                            id: 'project_name',
                            sortable: false,
                            accessor: ({ project_name }) => <span>{project_name}</span>,
                        },

                        {
                            Header: 'Last Updated',
                            id: 'updated_at',
                            accessor: ({ updated_at }) => (
                                <span>{updated_at && shortDateFormat(updated_at, dateTimeRules)}</span>
                            ),
                            isSortable: true,
                        },
                        {
                            Header: 'Actions',
                            id: 'actions',
                            sortable: false,
                            accessor: (full) => this.renderTableActionButtons(full.id, full),
                            maxWidth: 80,
                            minWidth: 80,
                        },
                    ]}
                    forceReload={
                        this.props.checklistStore.deleteSuccess || this.props.checklistStore.addOrUpdatedTemplate
                    }
                    filters={appliedFilters}
                    lastListLoadTime={this.props.checklistStore.lastListLoadTimeChckTemplate}
                    header={
                        <GenericFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.checklistStore.setFilter(name, value)}
                            onFilter={() => this.props.checklistStore.onFilter()}
                            loading={loading}
                            hasProjectFilter={true}
                        />
                    }
                    requestData={(params) => this.props.checklistStore.getChecklistTemplateByClientId(params)}
                />
                <Modal
                    className={`${"modal right fade modal_revised"}`}
                    show={this.state.showChecklistTemplateAddEdit}
                    onHide={this.handleClosesChecklistTemplateAddEditModal}
                >
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 38px)', minHeight: '500px', overflowY: 'auto' }}>
                        <div className='d-flex justify-content-between mt-2 mb-3'>
                            <div className="modal-body-custom__header">{t('Create checklist')}</div>
                            <div className='d-flex'>
                                <div className='me-3'>
                                    <Button fill onClick={this.savechecklist} className="btn-wd btn-fill">
                                        {t('Save')}
                                    </Button>
                                </div>
                                <div className='me-3'>
                                    <Button variant="secondary" onClick={this.handleClosesChecklistTemplateAddEditModal} className="btn-wd">
                                        {t('Close')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <ChecklistTemplateAddEdit add={this.add} id={this.state.id} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default inject(
    'checklistStore',
    'userStore',
    'commonStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(ChecklistTemplateList)))));
