import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';

import LoadingSpinner from '../../../elements/LoadingSpinner';
import GenericForm, { isTrue } from '../../../components/GenericForm/GenericForm';
import withLocalization from '../../../hoc/withLocalization';

import getSchema from '~/library/schemas/client';

const listUrl = '/admin/clients';

const uiSchema = {
    'ui:field': 'layout',
    'ui:layout': [
        {
            name: { md: 4 },
            status: { md: 4 },
            image: { md: 4 },
        },
        {
            allow_visitor_only: { md: 3 },
            allow_system_document: { md: 3 },
            allow_tip: { md: 3 },
            allow_multi_account_administration: { md: 3 },
            calculate_task_distance_with_company: { md: 4 }
        },
        {
            employment_fee: { md: 6 },
        },
        {
            max_users: { md: 6 },
            max_users_requested: { md: 6 },
        },
        {
            renew_date: { md: 4 },
            renew_manual: { md: 4 },
        },
        {
            biztype: { md: 4 },
            address: { md: 4 },
            tax_number: { md: 4 },
        },
        {
            User: { md: 12 },
        },
    ],
    status: {
        'ui:widget': 'ActiveInactive',
    },
    biztype: {
        'ui:widget': 'BusinessTypesSelect',
    },
    image: {
        'ui:widget': 'ImageUpload',
        'ui:imageContext': {
            model: 'Client',
            fileType: 'logo',
            id: 0,
        },
    },
    renew_manual: {
        'ui:widget': 'hidden',
    },
    renew_date: {
        'ui:widget': 'hidden',
    },
    allow_system_document: {
        'ui:widget': 'Radio',
    },
    allow_visitor_only: {
        'ui:widget': 'Radio',
    },
    allow_tip: {
        'ui:widget': 'Radio',
    },
    allow_multi_account_administration: {
        'ui:widget': 'Radio',
    },
    calculate_task_distance_with_company: {
        'ui:widget': 'Radio',
    },
    User: {
        'ui:field': 'layout',
        'ui:layout': [
            { first_name: { md: 4 }, last_name: { md: 4 }, email: { md: 4 } },
            { phone: { md: 4 }, username: { md: 4 }, password: { md: 4 } },
        ],
    },
};

class ClientsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: getSchema(),
        };
    }

    async loadData() {
        const { clientStore, commonStore } = this.props;
        let getId = (this.props.router.location.state && this.props.router.location.state.client) || null;
        const clientType = this.props.router.location.state && this.props.router.location.state.biztypeId > 0
            ? parseInt(this.props.router.location.state.biztypeId)
            : commonStore.config.biztypes[0].id;
        if (!getId) return clientStore.returnDefaultNew({ biztype: clientType });
        await clientStore.load(getId, !getId);

        // 
        let _schema = cloneDeep(this.state.schema);
        const data = commonStore.config.biztypes.find(ele => ele.id == clientStore.currentEntity.client.biztype && ele.data.allow_multiple_branches)
        if (data) {
            _schema.properties.max_branches_allowed = { type: 'integer', title: 'Max branches (Locations)' }
            uiSchema['ui:layout'][2].max_branches_allowed = { md: 6 }
        }
        else delete _schema.properties.max_branches_allowed;
        this.setState({ schema: _schema})
    }

    componentWillMount() {
        this.props.clientStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    onSave(values) {
        const {
            clientStore, t, commonStore
        } = this.props;
        let getId = (this.props.router.location.state && this.props.router.location.state.client) || null;
        values.User.user_type = 'admin';
        if (getId) {
            return clientStore.save(values, !getId).then(result => {
                if (!result.client || !result.client.id) {
                    // some error
                    commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                    return false;
                }
                commonStore.addNotification(t('Saved'), null, 'success');
                this.props.router.navigate(listUrl);
                return true;
            });
        }
        else {
            return clientStore.save(values, !getId).then(result => {
                if (!result.client || !result.client.id) {
                    // some error
                    commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                    return false;
                }
                commonStore.addNotification(t('Saved'), null, 'success');
                this.props.router.navigate(listUrl);
                return true;
            });
        }
    }

    transformForSchema = entity => {
        const e = cloneDeep(entity);
        if (e.renew_date && typeof e.renew_date === 'string') e.renew_date = e.renew_date.split('T')[0];
        return e;
    };

    updateSchema(allFormData) {
        const { commonStore } = this.props
        const { schema } = allFormData;
        let _schema = cloneDeep(schema);
        const data = commonStore.config.biztypes.find(ele => ele.id == allFormData.formData.biztype && ele.data.allow_multiple_branches)
        if (data) {
            _schema.properties.max_branches_allowed = { type: 'integer', title: 'Max branches (Locations)' }
            uiSchema['ui:layout'][2].max_branches_allowed = { md: 6 }
        }
        else delete _schema.properties.max_branches_allowed;
        this.setState({ schema: _schema})
    }

    render() {
        const { clientStore } = this.props;
        const { loading, currentEntity } = clientStore;
        let getId = (this.props.router.location.state && this.props.router.location.state.client) || null;
        if (loading) return <LoadingSpinner />;
        return (
            <div className="primary-page">
                <GenericForm
                    entity={this.transformForSchema(currentEntity.client)}
                    updateSchema={(schema, formData) => this.updateSchema(schema, formData)}
                    uiSchema={uiSchema}
                    schema={this.state.schema}
                    translationScope="forms.client"
                    onSave={values => this.onSave(values)}
                    listUrl={listUrl}
                    isAdding={getId}
                />
            </div>
        );
    }
}

export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(ClientsForm))));

