import React, { Component } from 'react';

// Components
import applicationRouter from '~/hoc/applicationRouter';
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
class Login extends Component {
    componentWillUnmount() {
        const { authStore } = this.props;
        authStore.reset();
    }

    handleEmailChange = (e) => this.props.authStore.setEmail(e.target.value);

    handlePasswordChange = (e) => this.props.authStore.setPassword(e.target.value);

    handleSubmitForm = (e) => {
        e.preventDefault();
        const { authStore } = this.props;
        authStore
            .login()
            .then(() => {
                const lastPage = sessionStorage.getItem('lastPage');
                if (lastPage) {
                    sessionStorage.removeItem('lastPage');
                    this.props.router.navigate(`${lastPage}`);
                } else {
                    this.props.router.navigate('/admin');
                }
            })
            .catch((e) => {
                console.error(e);
            });
    };

    constructor(props) {
        super(props);
        this.state = {
            cardHidden: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ cardHidden: false });
        }, 700);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <form className="login-container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div className="form-group business-name">
                                <label htmlFor="companyName" className="big-text fw-bold">
                                    {t('Email')} / {t('Username')}
                                </label>
                                <label className="red-star">*</label>
                                <p className="small-text st fw-bold">{t('Add email address')} / {t('Username')}</p>
                                <input
                                    type="text"
                                    className="form-control login-input mb_25 mt-12"
                                    placeholder={`${t('Email address')} / ${t('Username')}`}
                                    required
                                    onChange={(e) => this.handleEmailChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div className="form-group  business-name ">
                                <label htmlFor="Password" className="big-text">
                                    {t('Password')}
                                </label>
                                <label className="red-star">*</label>
                                <p className="small-text st">{t('Add password used to have an access to account')}</p>
                                <input
                                    type="password"
                                    className="form-control login-input mt-12 mb_25"
                                    placeholder={t('Password')}
                                    required
                                    onChange={(e) => this.handlePasswordChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-block fwBold fs18 loginbutton" onClick={this.handleSubmitForm}>
                        {t("Login")}
                    </button>
                </form>
            </div>
        );
    }
}

export default inject('authStore','commonStore')(applicationRouter(withLocalization(observer(Login))));
