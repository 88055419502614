import { observable, action, computed, makeObservable } from 'mobx';

// import getBase64 from '~/utils/getBase64';
import agent from '../agent';

const currentChecklistInit = {
    user_id: null,
    project_id:null,
    checklist_templates_id: null,
    status: 'new',
    completed_date: '',
    element_data:null,
    to_project:false,
    isSendEmail:true,
    cornjob_type: 'None',
    cornjob_duration: '',
    allow_check_all: false
};

const currentChecklistTemplateInit = {
    searchText: '',
    elementlist: [],
    assignedelements: [],
    checklistname: '',
    id: 0
}

class checklistStore {
    constructor() {
        makeObservable(this, {
            currentList: observable,
            currentChecklist: observable,
            lastListLoadTime: observable,
            lastListLoadTimeChckTemplate: observable,
            currentEntity: observable,
            updatingErrors: observable,
            updating: observable,
            isAllChecklistShow: observable,
            loading: observable,
            deleteSuccess: observable,
            addOrUpdated: observable,
            addOrUpdatedTemplate: observable,
            currentChecklistLookup: observable,
            currentChecklistTemplate: observable,
            checklistTemplateData: observable,
            loadingLookup: observable,
            currentParams: observable,
            filters: observable,
            elementFilters: observable,
            appliedFilters: observable,
            elementAppliedFilters: observable,
            resetLastListLoadTime: action,
            resetLastListLoadTimeCheckTemplate: action,
            setFilter: action,
            onFilter: action,
            setElementFilter: action,
            onElementFilter: action,
            listelementbyclientid: action,
            getElementTemplateByClientId: action,
            getElementById: action,
            addelement: action,
            deleteElementById: action,
            getChecklistTemplateByClientId: action,
            addChecklistTempalte: action,
            addChecklistTempalteNew: action,
            searchElement: action,
            getChecklistTemplateById: action,
            checklistTemplateDelete: action,
            checklistDelete: action,
            getElementChildByParentId: action,
            copyElementById: action,
            getMultipleElements: action,
            assignChecklist: action,
            scheduleToNone: action,
            checkChecklistElement: action,
            checkAllChecklist: action,
            getChecklists: action,
            getChecklistsFilter: action,
            getPreparedData: computed,
            returnDefaultNew: action,
            load: action,
            getChecklistById: action,
            handleChange: action,
            loadLookup: action,
            loadLookupCheckWidget: action,
            returnDefaultNewChecklistTemplate: action,
            loadChecklistTemplate: action,
            checklistTemplateDoSearch: action,
            onassignedchecklist: action,
            unassignedelement: action,
            checklistTemplateHandleChange: action,
            setChecklistTemplateData: action,
            updateChecklistElement: action,
            approveChecklist: action,
            deviationChecklist: action,
            getChecklistReport: action,
            updateIsAllChecklistShow: action
        });
    }

    currentList = [];
    currentChecklist = currentChecklistInit;
    lastListLoadTime = null;
    lastListLoadTimeChckTemplate = null;
    currentEntity = {};
    updatingErrors = null;
    updating = false;
    isAllChecklistShow = false;
    loading = false;
    deleteSuccess = false;
    addOrUpdated = false;
    addOrUpdatedTemplate = false;
    currentChecklistLookup = [];
    currentChecklistTemplate = currentChecklistTemplateInit;
    checklistTemplateData = null;
    loadingLookup = false;
    currentParams = {};
    timeout = 0;
    filters = {
        status: '',
        name: '',
        project: '',
        isSchedule: false,
    };
    elementFilters = {
        name: '',
    };
    appliedFilters = {
        status: '',
        name: '',
        project: '',
        isSchedule: false,
    };
    elementAppliedFilters = {
        name: '',
    };

    resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    resetLastListLoadTimeCheckTemplate() {
        this.lastListLoadTimeChckTemplate = new Date();
    }

    setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
        this.currentParams.filters = filters;
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
        // requestParams.filters = this.filters;
        // this.loadList(requestParams);
    }

    setElementFilter(name, value) {
        const elementFilters = Object.assign({}, this.elementFilters);
        elementFilters[name] = value;
        this.elementFilters = elementFilters;
    }

    onElementFilter() {
        this.elementAppliedFilters = Object.assign({}, this.elementFilters);
        // requestParams.filters = this.filters;
        // this.loadList(requestParams);
    }

    listelementbyclientid() {
        return agent.checklist
            .listelementbyclientid()
            .then(
                action((list) => {
                    // this.requestParams = data;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    getElementTemplateByClientId(params) {
        return agent.checklist
            .getChecklistTemplateByClientIdPagination(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    getElementById(id) {
        return agent.checklist
            .getElementById(id)
            .then(
                action((element) => {
                    // this.requestParams = data;
                    this.currentEntity = element;
                    return element;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    addelement(data) {
        this.updating = true;
        return agent.checklist
            .addelement(data)
            .then(
                action((element) => {
                    this.currentEntity =element.element_template;
                    this.updating = false;
                    return element.element_template;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    async deleteElementById(id) {
        await agent.checklist.deleteElementById(id);
        this.deleteSuccess = true;
        return 1;
    }

    getChecklistTemplateByClientId(params) {
        return agent.checklist
            .getChecklistTemplateByClientId(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    // list.time = new Date();
                    // this.lastListLoadTime = list.time;
                    // this.currentList = list;
                    this.currentList = list;
                    list.time = new Date();
                    this.lastListLoadTimeChckTemplate = list.time;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    addChecklistTempalte(data) {
        this.updating = true;
        return agent.checklist
            .addChecklistTempalte(data)
            .then(
                action((checklist) => {
                    this.addOrUpdatedTemplate = true;
                    return this.currentEntity;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    addChecklistTempalteNew() {
        this.updating = true;
        return agent.checklist
            .addChecklistTempalte(this.checklistTemplateData)
            .then(
                action((checklist) => {
                    this.addOrUpdatedTemplate = true;
                    return this.currentEntity;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    searchElement(params) {
        return agent.checklist
            .searchElement(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    getChecklistTemplateById(id) {
        this.loading = true;
        return agent.checklist
            .getChecklistTemplateById(id)
            .then(
                action((response) => {
                    if (!response.checklist) response.checklist = {};

                    this.currentEntity = response;
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    async checklistTemplateDelete(id) {
        await agent.checklist.deleteChecklistTemplateById(id);
        this.deleteSuccess = true;
        return 1;
    }

    async checklistDelete(id) {
        await agent.checklist.deleteChecklistById(id);
        this.deleteSuccess = true;
        return 1;
    }

    searchElement(data) {
        return agent.checklist
            .searchElement(data)
            .then(
                action((element) => {
                    return element;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    getElementChildByParentId(data) {
        return agent.checklist
            .getElementChildByParentId(data)
            .then(
                action((element) => {
                    return element;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    copyElementById(data) {
        return agent.checklist
            .copyElementById(data)
            .then(
                action((element) => {
                    return element;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    getMultipleElements(data) {
        return agent.checklist
            .getMultipleAttachments(data)
            .then(
                action((attachments) => {
                    return attachments;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    addChecklistTempalte(data) {
        this.updating = true;
        return agent.checklist
            .addChecklistTempalte(data)
            .then(
                action((element) => {
                    this.currentEntity = element;
                    this.updating = false;
                    this.resetLastListLoadTimeCheckTemplate();
                    return this.currentEntity;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    assignChecklist(data) {
        this.updating = true;
        return agent.checklist
            .assignChecklist(data)
            .then(
                action((checklist) => {
                    this.currentEntity = checklist;
                    this.updating = false;
                    this.addOrUpdated = true;
                    this.resetLastListLoadTime();
                    return this.currentEntity;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }
    scheduleToNone(data) {
        this.updating = true;
        return agent.checklist
            .scheduleToNone(data)
            .then(
                action((checklist) => {
                    this.currentEntity = checklist;
                    this.updating = false;
                    this.addOrUpdated = true;
                    this.resetLastListLoadTime();
                    return this.currentEntity;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    checkChecklistElement(data) {
        this.updating = true;
        return agent.checklist
            .checkChecklistElement(data)
            .then(
                action((checklist) => {
                    // this.currentEntity = checklist;
                    // this.updating = false;
                    // this.addOrUpdated = true;
                    // this.resetLastListLoadTime();
                    return checklist;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    checkAllChecklist(data) {
        this.updating = true;
        return agent.checklist
            .checkAllChecklist(data)
            .then(
                action((checklist) => {
                    // this.currentEntity = checklist;
                    // this.updating = false;
                    // this.addOrUpdated = true;
                    // this.resetLastListLoadTime();
                    return checklist;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    getChecklists(params) {
        return agent.checklist
            .getChecklists(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    this.currentList = list;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }
    getChecklistsFilter(params) {
        this.currentParams = params;
        return agent.checklist
            .getChecklistsIsSchedule(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    this.currentList = list;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    get getPreparedData() {
        const {
            id,
            checklist_templates_id,
            name,
            status,
            project_id,
            user_id,
            element_data,
            completed_at,
        } = this.currentChecklist;

        return {
            id,
            checklist_templates_id,
            name,
            status,
            project_id,
            user_id,
            element_data,
            completed_at,
        };
    }

    returnDefaultNew(params) {
        this.currentChecklist = {
            ...currentChecklistInit,
            ...params,
        };
        this.loading = false;
        return this.currentChecklist;
    }

    load(id) {
        this.loading = true;
        return agent.checklist
            .getChecklistById(id)
            .then(
                action(response => {
                    if(response.checklist){
                        this.currentChecklist = response.checklist[0];
                        this.currentChecklist.isSendEmail = true;                                              
                    } else {
                        this.currentChecklist = {};
                    }
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    getChecklistById(id) {
        this.loading = true;        
        return agent.checklist
            .getChecklistById(id)
            .then(
                action(response => {
                    this.loading = false;
                    return response.checklist[0];
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    handleChange = (name, value) => {
        const checklist = { ...this.currentChecklist };
        if (name === 'project_id') {
            checklist.checklist_templates_id = '';
        }
        checklist[name] = value;
        this.currentChecklist = checklist;
    };

    loadLookup(mode, name) {
        var data = {
            name: name,
            mode: mode,
        };        
        return agent.checklist
            .lookupByName(data)
            .then(
                action((list) => {
                    this.currentChecklistLookup = list;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    loadLookupCheckWidget(mode, name, all) {
        var data = {
            name: name,
            mode: mode,
            all: all
        };        
        return agent.checklist
            .lookupByName(data)
            .then(
                action((list) => {
                    this.currentChecklistLookup = list;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    returnDefaultNewChecklistTemplate(params) {
        this.currentChecklistTemplate = {
            ...currentChecklistTemplateInit,
            ...params,
        };
        this.loading = false;
        return this.currentChecklistTemplate;
    }

    loadChecklistTemplate = async (id, add) => {

        if (id && id >0) {
            await this.getChecklistTemplateById(id, add).then((data) => {
                this.currentChecklistTemplate.checklistname = data.checklist[0].name;
                this.currentChecklistTemplate.assignedelements = data.checklist[0].element_data;
                this.currentChecklistTemplate.id = data.checklist[0].id;
                this.currentChecklistTemplate.project_id = data.checklist[0].project_id;
                this.setChecklistTemplateData();
                return true;
            });
        }
    };

    checklistTemplateDoSearch(value) {

        this.currentChecklistTemplate.searchText = value;
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.search(value);
        }, 300);
    }

    search = async (value) => {
        let data = {
            name: value,
        };
        this.searchElement(data).then((list) => {
            let currentlist = list.elements;

            if (this.currentChecklistTemplate.assignedelements.length > 0) {
                this.currentChecklistTemplate.assignedelements.forEach((item, index) => {
                    currentlist = currentlist.filter((element) => element.id !== item.id);
                });
            }
            this.currentChecklistTemplate.elementlist= currentlist;
        });
    };

    onassignedchecklist = async (item) => {
        let currentassignedarray = this.currentChecklistTemplate.assignedelements;
        let elementsData= {};
        let reqData ={
            parent_id:item.id
        };
        this.getElementChildByParentId(reqData).then((elementsResult) => {
            elementsData = elementsResult.elements.elements; 
            item["elements"] = elementsData;
            currentassignedarray.push(item);
            let currentlist = [];
            currentlist = this.currentChecklistTemplate.elementlist.filter((element) => element.id !== item.id);
            this.currentChecklistTemplate.assignedelements = currentassignedarray;
            this.currentChecklistTemplate.elementlist = currentlist;
            this.setChecklistTemplateData();
            return true;
        });
    };

    unassignedelement = async (item) => {
        let currentassignedarray = this.currentChecklistTemplate.assignedelements.filter((element) => element.id !== item.id);
        this.currentChecklistTemplate.assignedelements = currentassignedarray;
        this.search();
        this.setChecklistTemplateData();
        return true;
    };

    checklistTemplateHandleChange = async (name,value) => {
        this.currentChecklistTemplate[name] = value;
        this.setChecklistTemplateData();
        return true;
    };

    setChecklistTemplateData = async () => {

        let data = {};
        data.id = this.currentChecklistTemplate.id;
        data.name = this.currentChecklistTemplate.checklistname;
        data.project_id=this.currentChecklistTemplate.project_id;
        data.element_data = this.currentChecklistTemplate.assignedelements;
        this.checklistTemplateData = data;
        return true;
    };

    updateChecklistElement = async (item) => {
        var newAssignedElements = [];
        this.currentChecklistTemplate.assignedelements.forEach( async element => {
            if(element.id === item.id){
                newAssignedElements.push(item);
            } else {
                newAssignedElements.push(element);
            }
        });
        this.currentChecklistTemplate.assignedelements = newAssignedElements;
        this.setChecklistTemplateData();
        return true;
    };

    approveChecklist(data) {
        this.updating = true;
        return agent.checklist
            .approveChecklist(data)
            .then(
                action((checklist) => {
                    this.resetLastListLoadTime();
                    return true;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    deviationChecklist(data) {
        this.updating = true;
        return agent.checklist
            .deviationChecklist(data)
            .then(
                action((checklist) => {
                    return true;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    getChecklistReport(type) {
        this.updating = true;
        return agent.checklist
            .report(type)
            .then(
                action((result) => {
                    // this.resetLastListLoadTime();
                    return result;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    updateIsAllChecklistShow(value) {
        this.isAllChecklistShow = value;
    }

    // @action updateChecklistChildElement = async (item) => {
    //     let path = [item.id];
    //     if(item.idreference){
    //         path = item.idreference.splite("->");
    //     }




    //     var newAssignedElements = [];
    //     this.currentChecklistTemplate.assignedelements.forEach( async element => {
    //         if(element.id ==path[0]){
    //             newAssignedElements.push(item);
    //         } else {
    //             newAssignedElements.push(element);
    //         }
    //     });
    //     this.currentChecklistTemplate.assignedelements = newAssignedElements;
    //     this.setChecklistTemplateData();
    //     return true;
    // };
}


const _checklistStore = new checklistStore()
export default _checklistStore;
